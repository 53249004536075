import React from 'react';
import PropTypes from 'prop-types';
import Progress from 'components/progress/progress';

class ProgressController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			keys: 0,
			unlockedKeys: 0
		};
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		let areaData = this.props.areasData.find((maze) => {return maze.id === this.props.playerData.mazeId;});
		let keys = 0;
		if (
			areaData.hasOwnProperty('portal') && 
			areaData.portal &&
			areaData.portal.keys) keys = areaData.portal.keys;
	
		let unlockedKeys = this.props.playerData.missionsCompleted.filter((mission) => {
			return (mission.mazeId === areaData.id);
		}).length;

		this.setState({keys, unlockedKeys});
	}

	/**
	 * Component did update
	 * @param {object} prevProps 
	 */
	componentDidUpdate = (prevProps) => {
		/* Update number of unlocked keys if portal popup was displayed */
		if (prevProps.showPortal === false && this.props.showPortal === true) {
			let areaData = this.props.areasData.find((maze) => {return maze.id === this.props.playerData.mazeId;});
			let unlockedKeys = this.props.playerData.missionsCompleted.filter((mission) => {
				return (mission.mazeId === areaData.id);
			}).length;
			this.setState({unlockedKeys});
		}
	}

	/**
	 * Render component
	 */
	render = () => {
		return (
			<Progress 
				hideProgress={this.props.hideProgress} 
				isFreeplay={this.props.isFreeplay}
				isQuickGame={this.props.isQuickGame}
				keys={this.state.keys}
				unlockedKeys={this.state.unlockedKeys}
			/>
		);
	}
}

ProgressController.propTypes = {
	hideProgress: PropTypes.bool.isRequired,
	isFreeplay: PropTypes.bool.isRequired,
	showPortal: PropTypes.bool.isRequired,
	isQuickGame: PropTypes.bool.isRequired,
	areasData: PropTypes.array.isRequired,
	playerData: PropTypes.object.isRequired
};

export default ProgressController;