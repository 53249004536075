import React from 'react';
import PropTypes from 'prop-types';
import secretsData from 'data/secrets-data';
import './user-stats.scss';

const UserStats = ({userObj, setUserId, areasData, missionsData, surveysData}) => {
	if (!userObj) {
		return (
			<div className="UserStats">ingen data</div>
		);
	}

	return (
		<div className="UserStats">
			<div className="UserStats-backBtn" onClick={() => {setUserId(null);}}>Tilbage</div>

			{/* General info */}
			<div className="UserStats-section">
				<div className="UserStats-info"><span>Brugernavn: </span>{userObj.id}</div>
				<div className="UserStats-info"><span>Registreret: </span>{userObj.created}</div>
				<div className="UserStats-info"><span>Sidste login: </span>{userObj.lastLogin}</div>
				<div className="UserStats-info">
					<span>Antal login: </span>{(userObj.numberOfLogins ? userObj.numberOfLogins.length : 0)}
				</div>
				<div className="UserStats-info">
					<span>Antal spil: </span>{(userObj.gamesPlayed ? userObj.gamesPlayed.length : 0)}
				</div>
			</div>

			{/* Secrets */}
			<div className="UserStats-section">
				<div className="UserStats-sectionTitle">Hemmeligheder</div>
				<hr />
				{userObj.secretsDiscovered && userObj.secretsDiscovered.map((secretId, index) => {
					let secretTitle = (secretsData.some((secretData) => {return secretData.id === secretId;}) 
						? secretsData.filter((secretData) => {return secretData.id === secretId;})[0].title 
						: null
					);
					if (secretTitle) {
						return (
							<div key={index}>{secretTitle}</div>
						);
					}
					return null;
				})}
			</div>
			

			{/* Missions */}
			<div className="UserStats-section">
				<div className="UserStats-sectionTitle">Missioner</div>
				<hr />
				{userObj.missionsCompleted && userObj.missionsCompleted.map((mission, index) => {
					let missionTitle = (missionsData.some((missionData) => {return missionData.id === mission.id;}) 
						? missionsData.filter((missionData) => {return missionData.id === mission.id;})[0].title 
						: null
					);
					let mazeId = mission.mazeId;
					if (missionTitle) {
						return (
							<div key={index}>{missionTitle + (mazeId ? ' (' + mazeId + ')' : '')}</div>
						);
					}
					return null;
				})}
			</div>

			{/* Portals */}
			<div className="UserStats-section">
				<div className="UserStats-sectionTitle">Portaler åbnet</div>
				<hr />
				<div>{(userObj.mazeId ? (userObj.mazeId - 1) : 0)}</div>
			</div>
			
			{/* Surveys */}
			<div className="UserStats-section">
				<div className="UserStats-sectionTitle">Refleksionsøvelser</div>
				<hr />
				{areasData.map((area) => {
					if (userObj.hasOwnProperty('surveys') && userObj.surveys.length > 0) {
						let completedSurveysInMaze = userObj.surveys.filter((survey) => {
							return survey.mazeId === area.id;
						});
						return (
							<div key={area.id} className="UserStats-maze">
								<div className="UserStats-mazeTitle">{area.title}:</div>
								{completedSurveysInMaze.map((survey, index) => {
									let surveyData = 
										surveysData.filter((surveyData) => {return surveyData.id === survey.id;})[0];
									return (
										<div key={index} className="UserStats-survey">
											<div className="UserStats-surveyTitle">{surveyData.title}</div>
											<div className="UserStats-surveySteps">
												{surveyData.steps.map((step, index) => {
													if (step.type !== 'intro') {
														return (
															<div key={index} className="UserStats-surveyStep">
																<div className="UserStats-surveyQ">
																	{step.text}
																</div>
																<div className="UserStats-surveyA">
																	{(survey.hasOwnProperty('inputs') && 
																		survey.inputs.length > index
																	) && survey.inputs[index]}
																</div>
															</div>
														);
													}
													return null;
												})}
											</div>
										</div>
									);
								})}
							</div>
						);
					}
					return null;
				})}
			</div>
		</div>
	);
};

UserStats.propTypes = {
	userObj: PropTypes.object,
	setUserId: PropTypes.func.isRequired,
	areasData: PropTypes.array.isRequired,
	missionsData: PropTypes.array.isRequired,
	surveysData: PropTypes.array.isRequired,
};

export default UserStats;