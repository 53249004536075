import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './deck-popup.scss';

const DeckPopup = ({isCreatingDeck, decks, cards, toggleDeckPopup, createNewDeck, deleteDeck}) => {

	let [newDeckObj, setNewDeckObj] = useState({id: '', title: ''});
	let [errorMsg, setErrorMsg] = useState('');
	let [deleteErrorMsg, setDeleteErrorMsg] = useState('');

	const updateDeck = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		let updatedDeckObj = Object.assign({}, newDeckObj, {[name]: value});
		setNewDeckObj(updatedDeckObj);
		setErrorMsg('');
	};

	const handleCreateDeck = () => {
		/* Missing input */
		if (newDeckObj.id.length === 0 || newDeckObj.title.length === 0) return;

		/* Error: invalid character in id */
		if (newDeckObj.id.indexOf(' ') >= 0) {
			setErrorMsg('Id må ikke indeholde mellemrum.');
			return;
		}

		/* Error: deck id already exists */
		if (decks.some((deck) => {return deck.id === newDeckObj.id;})) {
			setErrorMsg('Der eksisterer allerede et dæk med det id.');
			return;
		}

		/* All ok */
		createNewDeck(newDeckObj);
	};

	/* Delete ikon */
	let closeImg = null;
	try {
		closeImg = require('../../../assets/images/icon-close.svg');
	} catch (error) {
		console.error(error);
	}

	const permanentDeckIds = ['intro', 'morning', 'basic', 'pause'];
	const handleDeleteDeck = (deckId) => {
		/* Cannot delete permanent decks */
		if (permanentDeckIds.indexOf(deckId) >= 0) {
			setDeleteErrorMsg('Dæk med id ' + deckId + ' kan ikke slettes.');
			return;
		}

		/* Cannot delete decks containing cards */
		let numberOfCards = cards.filter((card) => {return card.deckId === deckId;}).length;
		if (numberOfCards > 0) {
			setDeleteErrorMsg('Dæk med kort kan ikke slettes.');
			return;
		}

		/* All ok */
		deleteDeck(deckId);
	};


	return (
		<div className="DeckPopup">
			<div className="DeckPopup-content">
				<div className="DeckPopup-backBtn" onClick={() => {toggleDeckPopup(false);}} />
		
				{/* Create new deck */}
				<div className="DeckPopup-section">
					<div className="DeckPopup-sectionTitle">Opret nyt dæk</div>
					<div className="DeckPopup-deckInfo">
						<span>Id:</span>
						<input 
							type="text"
							name="id"
							value={newDeckObj.id}
							onChange={(event) => {updateDeck(event);}}
						/>
					</div>
					<div className="DeckPopup-deckInfo">
						<span>Titel:</span>
						<input 
							type="text"
							name="title"
							value={newDeckObj.title}
							onChange={(event) => {updateDeck(event);}}
						/>
					</div>

					{/* Error message */}
					<div className="DeckPopup-errorMsg">{errorMsg}</div>

					{/* Save button */}
					<div 
						className={'DeckPopup-saveDeckBtn' + 
						(isCreatingDeck ? ' DeckPopup-saveDeckBtn--saving' : '') +
						(newDeckObj.id.length > 0 && newDeckObj.title.length > 0 ? ' active' : '')
						}
						onClick={()=> {handleCreateDeck();}}
					>Opret</div>				
				</div>

				<div className="DeckPopup-section">
					<div className="DeckPopup-sectionTitle">Oprettede dæk</div>
					<div className="DeckPopup-decks">
						<div className="DeckPopup-decksHeader">
							<div className="DeckPopup-deckId">Id</div>
							<div className="DeckPopup-deckTitle">Title</div>
							<div className="DeckPopup-deckCards">Kort</div>
							<div className="DeckPopup-deckDelete"></div>
						</div>
						<div className="DeckPopup-decksBody">
							{decks.map((deck, index) => {
								let numberOfCards = cards.filter((card) => {return card.deckId === deck.id;}).length;
								return (
									<div key={index} className="DeckPopup-deck">
										<div className="DeckPopup-deckId">{deck.id}</div>
										<div className="DeckPopup-deckTitle">{deck.title}</div>
										<div className="DeckPopup-deckCards">{numberOfCards}</div>
										{(permanentDeckIds.indexOf(deck.id) < 0 && numberOfCards === 0) && 
											<div 
												className="DeckPopup-deckDelete" 
												onClick={() => {handleDeleteDeck(deck.id);}}
											>
												<img src={closeImg} alt="delete" />
											</div>
										}
									</div>
								);
							})}
						</div>
					</div>
					<div className="DeckPopup-errorMsg">{deleteErrorMsg}</div>
				</div>
				
			</div>
		</div>
	);
};



DeckPopup.propTypes = {
	isCreatingDeck: PropTypes.bool.isRequired,
	decks: PropTypes.array.isRequired,
	cards: PropTypes.array.isRequired,
	toggleDeckPopup: PropTypes.func.isRequired,
	createNewDeck: PropTypes.func.isRequired,
	deleteDeck: PropTypes.func.isRequired
};

export default DeckPopup;