import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {deleteCookie} from 'helpers/cookie-helper';
import MainMenu from 'components/main-menu/main-menu';
import IntroController from 'components/intro/intro-controller';
import MazeGameController from 'components/maze-game/maze-game-controller';

const gamePages = {
	menu: {component: MainMenu},
	intro: {component: IntroController},
	mazeGame: {component: MazeGameController},
};

class GameController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			pageId: 'menu',
			animateSlideIn: true,
			isQuickGame: false,
			cardDetention: [],
			playCount: 0,
		};
	}

	/**
	 * Update play count
	 * @param {number} playCount 
	 */
	updatePlayCount = (playCount) => {
		this.setState({playCount});
	}

	/**
	 * Update card detention
	 * @param {array} cardDetention 
	 */
	updateCardDetention = (cardDetention) => {
		this.setState({cardDetention});
	}

	/**
	 * Show page
	 * @param {string} pageId 
	 */
	handleShowPage = (pageId, param = false) => {
		let isQuickGame = false;
		if (pageId === 'mazeGame' && param === true) {isQuickGame = true;}
		this.setState({pageId: pageId, animateSlideIn: false, isQuickGame});
	}

	/**
	 * Skip to last maze (dev/test servers only)
	 */
	handleSkipToLastMaze = () => {
		this.props.updatePlayerData({
			mazeId: 5,
			lockedCards: [],
			missionsCompleted: []
		}).then(() => {
			this.handleRefresh();
		});
	}

	/**
	 * Clear cache & user progress (dev/test servers only)
	 * @param {string} path 
	 */
	resetGame = () => {
		deleteCookie(appConfig.cookiesAcceptedCookieName);
		this.props.updatePlayerData({
			mazeId: 1,
			numberOfLogins: [],
			introSeen: false, 
			onboardingPlayed: false,
			gameEnded: false,
			gamesPlayed: [],
			missionsCompleted: [], 
			secretsDiscovered: [], 
			lockedCards: [],
			surveys: []
		}).then(() => {
			this.handleRefresh();
		});
	}

	/**
	 * Refresh page
	 */
	handleRefresh = () => {
		// eslint-disable-next-line no-restricted-globals
		window.location.reload();
	}

	/**
	 * Render component
	 */
	render() {
		let Component = MainMenu;
		if (gamePages.hasOwnProperty(this.state.pageId)) Component = gamePages[this.state.pageId].component;
		
		return (
			<React.Fragment>
				<Component
					animateSlideIn={this.state.animateSlideIn}
					playCount={this.state.playCount}
					missionId={null}
					isPlaying={false}
					isGameover={false}
					isQuickGame={this.state.isQuickGame}
					cardDetention={this.state.cardDetention}
					playerData={this.props.playerData}
					decksData={this.props.decksData}
					areasData={this.props.areasData}
					missionsData={this.props.missionsData}
					surveysData={this.props.surveysData}
					cardImages={this.props.cardImages}
					handleShowPage={this.handleShowPage}
					updatePlayerData={this.props.updatePlayerData}
					updateCardDetention={this.updateCardDetention}
					updatePlayCount={this.updatePlayCount}
					handleSkipToLastMaze={this.handleSkipToLastMaze}
					resetGame={this.resetGame}
					handleLogout={this.props.handleLogout}
				/>
			</React.Fragment>
		);
	}
}

GameController.propTypes = {
	playerData: PropTypes.object.isRequired,
	areasData: PropTypes.array.isRequired,
	decksData: PropTypes.array.isRequired,
	missionsData: PropTypes.array.isRequired,
	surveysData: PropTypes.array.isRequired,
	cardImages: PropTypes.array.isRequired,
	updatePlayerData: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default GameController;