import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useSpring, animated} from 'react-spring';
import {generalUiTexts} from 'data/ui-texts';
import {renderMarkdown} from 'helpers/text-helper';
import './card.scss';

const CardFeedback = (props) => {
	let {
		feedbackType, 
		cardData, 
		playerData, 
		selectedOption, 
		missionData, 
		goToNextGameStep 
	} = props;

	/* Animate card falling out */
	let [animateCard, setAnimateCard] = useState(false);
	let [animateCardDirection, setAnimateCardDirection] = useState(null);
	const handleGoToNextGameStep = () => {
		if (Math.random() < 0.5) {
			setAnimateCardDirection('left');
		} else {
			setAnimateCardDirection('right');
		}
		setAnimateCard(true);
		goToNextGameStep();
	};
	
	let title = null;
	let feedback = null;
	let backgroundImage = null;

	/* Intro card */
	if (cardData && (feedbackType === 'intro' || feedbackType === 'portal' || feedbackType === 'gameover')) {
		feedback = cardData.text;
		if (cardData.imageUrl) backgroundImage = 'url("' + cardData.imageUrl + '")';
		if (cardData.deckId === 'night') title = cardData.title;
	}
	
	/* Card feedback */
	if (
		feedbackType === 'feedback' && 
		selectedOption && selectedOption.feedback && selectedOption.feedback.defaultText
	) {
		feedback = selectedOption.feedback.defaultText;
		if (
			selectedOption.feedback.hasOwnProperty('condition') &&
			selectedOption.feedback.condition.type === 'secret' &&
			playerData &&
			playerData.secretsDiscovered.indexOf(selectedOption.feedback.condition.secretId) >= 0
		) {
			feedback = selectedOption.feedback.condition.text;
		}
	}
	/* Mission completed feedback */
	if (feedbackType === 'mission' && missionData) {
		feedback = JSON.parse(JSON.stringify(generalUiTexts.missionCompletedFeedback));
		feedback = feedback.replace('%missionTitle%', missionData.title);
	}

	/* Feedback image */
	if (feedbackType === 'feedback' || feedbackType === 'mission') {
		try {
			let cardImg = require('../../assets/images/cards/' + feedbackType + '.svg');
			backgroundImage = 'url("' + cardImg + '")';
		} catch (error) {
			console.error(error);
		}
	}
	if (feedbackType === 'portal') {
		try {
			let cardImg = require('../../assets/images/cards/feedback.svg');
			backgroundImage = 'url("' + cardImg + '")';
		} catch (error) {
			console.error(error);
		}
	}

	/* Create flip spring */
	const {opacity, rotY} = useSpring({
		opacity: 1,
		rotY: 0,
		delay: 250,
		from: {opacity: 0, rotY: 180},
		config: { mass: 5, tension: 500, friction: 80 }
	});
		

	return (
		<div 
			className={'Card' + 
				(animateCard ? ' animate' : '') + (animateCardDirection ? ' ' + animateCardDirection : '')}
		>
			<div className="Card-content">
				<animated.div 
					className="Card-back"
					style={{ 
						opacity: opacity.interpolate((o) => {return 1 - o;}), 
						transform: rotY.interpolate((rotY) => {return `perspective(600px) rotateY(${180 - rotY}deg)`;}) 
					}} 
				/>
				<animated.div
					className={'Card-front Card-front--' + feedbackType} 
					style={{
						opacity: opacity.interpolate((o) => {return o;}), 
						transform: rotY.interpolate((rotY) => {return `perspective(600px) rotateY(${-rotY}deg)`;}),
						backgroundImage: backgroundImage
					}}
				>
					<div>
						{title && <div className="Card-title">{title}</div>}
						{renderMarkdown(feedback)}
						<div className="Card-okBtn" onClick={() => {handleGoToNextGameStep();}}>
							{(feedbackType === 'mission' ? generalUiTexts.yay : generalUiTexts.ok)}
						</div>
					</div>
				</animated.div>
			</div>
		</div>
	);
};

CardFeedback.propTypes = {
	feedbackType: PropTypes.string.isRequired,
	cardData: PropTypes.object,
	playerData: PropTypes.object,
	selectedOption: PropTypes.object,
	missionData: PropTypes.object,
	goToNextGameStep: PropTypes.func.isRequired
};

export default CardFeedback;