let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;

if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

// env = 'english'; // ENGLISHMODE

let usersDbName = 'users';
let areasDbName = 'areas';
let decksDbName = 'decks';
let cardsDbName = 'cards';
let imagesDbName = 'images';
let missionsDbName = 'missions';
let surveysDbName = 'surveys';
let apiURL = 'http://localhost:8080/api/';
if (env === 'test' || env === 'demo') {
	apiURL = 'https://cgl-mazeout-test.web.app/api/';
	if (env === 'demo') {
		apiURL = 'https://cgl-mazeout-demo2.web.app/api/';
		usersDbName = 'users-demo';
		areasDbName = 'areas-demo';
		decksDbName = 'decks-demo';
		cardsDbName = 'cards-demo';
		imagesDbName = 'images-demo';
		missionsDbName = 'missions-demo';
		surveysDbName = 'surveys-demo';
	}
}

if (env === 'production') {
	apiURL = 'https://cgl-mazeout-production.web.app/api/';
}
if (env === 'english') {
	apiURL = 'https://cgl-mazeout-english.web.app/api/';
}



const appConfig = {
	env: env,
	name: 'mazeout',
	apiURL: apiURL,
	usersDbName: usersDbName,
	areasDbName: areasDbName,
	decksDbName: decksDbName,
	cardsDbName: cardsDbName,
	imagesDbName: imagesDbName,
	missionsDbName: missionsDbName,
	surveysDbName: surveysDbName,
	userNameMinLength: 3,
	userNameMaxLength: 15,
	gameDurationLimitSecs: 7 * 60,
	gameDurationSoftLimitSecs: 5 * 60,
	pauseIntervalSecs: 3 * 60,
	cardDetentionDuration: 2,
	cookiesAcceptedCookieName: 'mazeout_cookies_accepted',
	cookieGameProgressName: 'mazeout_cookies_progress'
};

export default appConfig;