import React from 'react';
import firebaseInit from 'firebase-init';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import {
	loadAreasData, 
	loadDecksData, 
	loadMissionsData, 
	loadSurveysData, 
	loadCardImages
} from 'helpers/game-content-helper';
import ImageLoader from 'components/image-loader/image-loader';
import AdminController from 'components/admin/admin-controller';
import GameStatsController from 'components/game-stats/game-stats-controller';
import LoginController from 'components/login/login-controller';
import ConnectionStatus from 'components/connection-status/connection-status';
import './app.scss';

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isOnline: true,
			areasData: [],
			decksData: [],
			missionsData: [],
			surveysData: [],
			cardImages: [],
		};
	}

	/**
	 * Component mounted, initialize app
	 */
	componentDidMount() {
		/* Initialize firebase */
		firebaseInit.then(() => {console.log('Firestore initialized');});

		/* Adjust font size */
		this.handleAdjustFontsize();
		window.addEventListener('orientationchange', this.handleAdjustFontsize);

		/* Add network status listeners */
		window.addEventListener('online', this.handleNetworkChange);
		window.addEventListener('offline', this.handleNetworkChange);

		/* Load game content */
		const db = firebase.firestore();
		Promise.all([
			loadAreasData(db),
			loadDecksData(db),
			loadMissionsData(db), 
			loadSurveysData(db), 
			loadCardImages(db)
		]).then((responses) => {
			let areasData = responses[0].areasData;
			let decksData = responses[1].decksData;
			let missionsData = responses[2].missionsData;
			let surveysData = responses[3].surveysData;
			let cardImages = responses[4].cardImages;
			
			decksData.forEach((deck) => {
				if (deck.cards && deck.cards.length > 0) {
					deck.cards.forEach((card) => {
						let imageUrl = null;
						if (card.imageFile) {
							if (cardImages.some((cardImage) => {
								return (cardImage.fileName === card.imageFile && cardImage.url);
							})) {
								imageUrl = cardImages.filter((cardImage) => {
									return cardImage.fileName === card.imageFile;
								})[0].url;
							}
						}
						card.imageUrl = imageUrl;
					});
				}
			});
			this.setState({
				isLoading: false, 
				areasData,
				decksData,
				missionsData,
				surveysData,
				cardImages
			});
		});
	}

	/**
	 * Component will unmount, remove event listeners
	 */
	componentWillUnmount() {
		window.removeEventListener('online', this.handleNetworkChange);
		window.removeEventListener('offline', this.handleNetworkChange);
	}

	/**
	 * Overwrite fontsize from stylesheet if screen is too narrow
	 */
	handleAdjustFontsize = () => {
		let width = window.innerWidth || document.documentElement.clientWidth || 
			document.getElementsByTagName('body')[0].clientWidth;

		var el = document.getElementById('app');
		var style = window.getComputedStyle(el, null).getPropertyValue('font-size');
		var fontSize = parseFloat(style); 
		let maxFontSize = 18 * (width / 360);
		if (fontSize > 18 * (width / 360)) el.style.fontSize = maxFontSize + 'px';
	}

	/**
	 * Handle change in network status (online/offline)
	 */
	handleNetworkChange = () => {
		let isOnline = navigator.onLine;
		if (isOnline !== this.state.isOnline) this.setState({isOnline});
	}
	
	/**
	 * Render app
	 */
	render() {
		if (window.location.pathname.toLowerCase().includes('admin')) {
			return <div id="app" className="App App--admin"><AdminController /></div>;
		}

		if (window.location.pathname.toLowerCase().includes('spildata')) {
			return <div id="app" className="App App--admin"><GameStatsController /></div>;
		}

		return (
			<div id="app" className="App">
				<LoginController 
					isLoading={this.state.isLoading}
					areasData={this.state.areasData}
					decksData={this.state.decksData}
					missionsData={this.state.missionsData}
					surveysData={this.state.surveysData}
					cardImages={this.state.cardImages}
				/>
				<ConnectionStatus isOnline={this.state.isOnline} />
				<ImageLoader cardImages={this.state.cardImages} />
			</div>
		);
	}
}

export default App;