import React from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import {generalUiTexts, errorUiTexts} from 'data/ui-texts';
import RangeSlider from 'components/range-slider/range-slider';
import './survey.scss';


const Survey = ({stepIndex, surveyData, goToStep, handleUpdateInput, toggleOption, handleSaveSurvey}) => {
	if (!surveyData) {
		return (
			<div className="Survey">
				<div className="Survey-title">{errorUiTexts.unknownSurvey}</div>
				<div className="Survey-continue" onClick={() => {handleSaveSurvey();}}>{errorUiTexts.continueGame}</div>
			</div>
		);		
	}

	let surveyStep = surveyData.steps[stepIndex];
	let numberOfsteps = surveyData.steps.length;
	let surveyImage = null;
	if (surveyStep.image && surveyStep.image.length > 0) {
		try {
			surveyImage = require('../../assets/images/surveys/' + surveyStep.image);
		} catch (error) {
			console.error(error);
		}
	}
	let showBackgroundFlowers = (
		surveyStep.type === 'textarea' || 
		(surveyStep.type === 'intro' && surveyImage === null)
	);

	let surveyClass = 'Survey Survey--' + surveyStep.type + (showBackgroundFlowers ? ' flowers' : '');
	if (surveyData.id === 'exit') surveyClass += ' exit';

	return (
		<div className={surveyClass}>
			<div className="Survey-content">
				{/* Image */}
				{surveyImage && <div className="Survey-image"><img src={surveyImage} alt=""/></div>}
			
				{/* Title */}
				{surveyStep.title && <div className="Survey-title">{surveyStep.title}</div>}
			
				{/* Text */}
				{surveyStep.text && <div className="Survey-text">{renderMarkdown(surveyStep.text)}</div>}

				{/* Progress number */}
				{surveyStep.showProgress && 
					<div className="Survey-progress">{stepIndex}/{(surveyData.steps.length - 1)}</div>}


				{/* Survey type: mulitple choice */}
				{surveyStep.type === 'multiple-choice' &&
					<div className={'Survey-options' + 
						(surveyStep.layoutType ? ' Survey-options--' + surveyStep.layoutType : '')}>
						{surveyStep.options.map((option) => {
							let className = 'Survey-option Survey-option--' + option.id;
							if (option.selected)  className = className + ' Survey-option--selected';
							return (
								<div 
									key={option.id}
									className={className}
									onClick={() => {toggleOption(option.id);}} 
								><span>{option.title}</span></div>
							);
						})}
					</div>
				}

				{/* Survey type: slider */}
				{surveyStep.type === 'slider' &&
					<div className="Survey-slider">
						<RangeSlider value={surveyData.steps[stepIndex].input} handleUpdateValue={handleUpdateInput} />
					</div>
				}

				{/* Survey type: textarea */}
				{(surveyStep.type === 'textarea') &&
					<div className="Survey-textarea">
						{surveyStep.inputTitle && <div className="Survey-subtitle">{surveyStep.inputTitle}</div>}
						<textarea 
							name="input"
							value={surveyData.steps[stepIndex].input}
							onChange={handleUpdateInput}
						/>
					</div>
				}

				{/* Survey type: multiple choice */}
				{(surveyStep.type === 'multiple-choice') &&
					<div className="Survey-input">
						<input 
							name="input"
							placeholder={(surveyStep.inputPlaceholder ? surveyStep.inputPlaceholder : '')}
							value={surveyData.steps[stepIndex].input}
							onChange={handleUpdateInput}
						/>
					</div>
				}

				{/* Button(s) */}
				<div className="Survey-buttons">
					<div 
						className="Survey-button"
						onClick={() => {
							(stepIndex + 1 < numberOfsteps ? goToStep(stepIndex + 1) : handleSaveSurvey());
						}}
					>{(surveyStep.buttonText ? surveyStep.buttonText : generalUiTexts.ok)}</div>
				</div>
			</div>
		</div>
	);
};

Survey.propTypes = {
	stepIndex: PropTypes.number.isRequired,
	surveyData: PropTypes.object,
	goToStep: PropTypes.func.isRequired,
	handleUpdateInput: PropTypes.func.isRequired,
	toggleOption: PropTypes.func.isRequired,
	handleSaveSurvey: PropTypes.func.isRequired
};

export default Survey;