import React, {useState} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {generalUiTexts} from 'data/ui-texts';
import Missions from 'components/missions/missions';
import SurveysPrintController from 'components/surveys-print/surveys-print-controller';
import Info from 'components/info/info';
import './main-menu.scss';

const MainMenu = (props) => {
	let {
		animateSlideIn,
		isPlaying, 
		isGameover,
		playerData,
		decksData,
		missionsData,
		handleShowPage, 
		handleContinueGame, 
		handleSkipToLastMaze,
		resetGame,
		handleLogout
	} = props;

	let [showMissions, setShowMissions] = useState(false);
	let [showSurveys, setShowSurveys] = useState(false);
	let [showInfo, setShowInfo] = useState(false);
	
	let playBtnText = generalUiTexts.startGame;
	if (isPlaying || (playerData.introSeen && !playerData.gameEnded)) playBtnText = generalUiTexts.continueGame;
	if ((!isPlaying || (isPlaying && isGameover)) && playerData.gameEnded) playBtnText = generalUiTexts.continuePlaying;
	
	const handlePlay = () => {
		if (!isPlaying && !playerData.introSeen) {
			handleShowPage('intro');
		} else {
			if (!isPlaying) {
				handleShowPage('mazeGame');
			} else {
				handleContinueGame();
			}
		}
	};

	return (
		<div className={'MainMenu' + (animateSlideIn ? ' slideIn' : '') + (isPlaying ? ' popup' : '')}>
			<div className="MainMenu-content">
				<div className="MainMenu-title" />
				<div className="MainMenu-playBtn" onClick={() => {handlePlay();}}>
					<span>{playBtnText}</span>
				</div>
				<div className="MainMenu-missionsBtn" onClick={() => {setShowMissions(true);}}>
					<span>{generalUiTexts.viewMissions}</span>
				</div>
				<div className="MainMenu-surveysBtn" onClick={() => {setShowSurveys(true);}}>
					<span>{generalUiTexts.viewSurveys}</span>
				</div>
				<div className="MainMenu-infoBtn" onClick={() => {setShowInfo(true);}} />
			</div>

			{/* Dev controls */}
			{((appConfig.env === 'development' || appConfig.env === 'test') && 
			!isPlaying && playerData.mazeId < 5) && <div 
				className="MainMenu-skipBtn" 
				onClick={() => {handleSkipToLastMaze();}}
			>Skip til 5</div>}

			{((appConfig.env === 'development' || appConfig.env === 'test') && !isPlaying) && <div 
				className="MainMenu-quickStartBtn" 
				onClick={() => {handleShowPage('mazeGame', true);}}
			>Hurtigt spil</div>}
			
			{((appConfig.env === 'development' || appConfig.env === 'test') && !isPlaying) && 
				<div className="MainMenu-resetCacheBtn" 
					onClick={() => {resetGame();}}>Reset</div>
			}
			{!isPlaying && <div className="MainMenu-logoutBtn"
				onClick={() => {handleLogout();}}>{generalUiTexts.logout}</div>
			}

			{/* Missions popup */}
			{showMissions && 
				<Missions 
					playerData={playerData}
					decksData={decksData}
					missionsData={missionsData}
					closePopup={() => {setShowMissions(false);}}
				/>
			}

			{/* Surveys popup */}
			{showSurveys && 
				<SurveysPrintController 
					playerData={playerData}
					closePopup={() => {setShowSurveys(false);}} 
				/>
			}

			{/* Info popup */}
			{showInfo && <Info closePopup={() => {setShowInfo(false);}} />}
		</div>				
	);
};

MainMenu.propTypes = {
	animateSlideIn: PropTypes.bool.isRequired,
	isPlaying: PropTypes.bool.isRequired,
	isGameover: PropTypes.bool.isRequired,
	playerData: PropTypes.object.isRequired,
	decksData: PropTypes.array.isRequired,
	missionsData: PropTypes.array.isRequired,
	handleShowPage: PropTypes.func,
	handleContinueGame: PropTypes.func,
	handleSkipToLastMaze: PropTypes.func,
	resetGame: PropTypes.func,
	handleLogout: PropTypes.func
};

export default MainMenu;