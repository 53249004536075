import React from 'react';
import PropTypes from 'prop-types';
import {loginUiTexts} from './ui-texts';
import './game-stats.scss';

const GameStats = ({isLoading, users, sortProperty, sortDirection, setUserId, handleSortBy, handleLogout}) => {
	return (
		<div className="GameStats">
			<div className="GameStats-title">Mazeout</div>
			<div className="GameStats-logout" onClick={() => {handleLogout();}}>{loginUiTexts.logout}</div>
			<div className="GameStats-users">
				{(!isLoading && users.length === 0) && <p>No data.</p>}
				{users.length > 0 && <div className="GameStats-headers">
					<div 
						className="GameStats-header"
						onClick={() => {
							handleSortBy('id', 
								(sortProperty === 'id' ? (sortDirection === 'ASC' ? 'DESC' : 'ASC') : 'ASC'));
						}}>Brugernavn <span></span></div>
					<div 
						className="GameStats-header"
						onClick={() => {
							handleSortBy('created', 
								(sortProperty === 'created' ? (sortDirection === 'ASC' ? 'DESC' : 'ASC') : 'ASC'));
						}}>Registreret <span></span></div>
					<div 
						className="GameStats-header"
						onClick={() => {
							handleSortBy('lastLogin', 
								(sortProperty === 'lastLogin' ? (sortDirection === 'ASC' ? 'DESC' : 'ASC') : 'ASC'));
						}}>Sidste login <span></span></div>
					<div 
						className="GameStats-header"
						onClick={() => {
							handleSortBy('mazeId', 
								(sortProperty === 'mazeId' ? (sortDirection === 'ASC' ? 'DESC' : 'ASC') : 'ASC'));
						}}>Maze #<span></span></div>
				</div>}
				{users.map((user, index) => {
					return (
						<div key={index} className="GameStats-user" onClick={() => {setUserId(user.id);}}>
							<div className="GameStats-data">{user.id}</div>
							<div className="GameStats-data">{user.created}</div>
							<div className="GameStats-data">{user.lastLogin}</div>
							<div className="GameStats-data">{(user.mazeId ? user.mazeId : 0)}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

GameStats.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	users: PropTypes.array.isRequired,
	sortProperty: PropTypes.string.isRequired,
	sortDirection: PropTypes.string.isRequired,
	setUserId: PropTypes.func.isRequired,
	handleSortBy: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default GameStats;