import React, {Component} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';
import appConfig from 'config/app.config';
import {loadAreasData, loadMissionsData, loadSurveysData} from 'helpers/game-content-helper';
import {sortArrayByProperty} from 'helpers/array-helper';
import GameStatsLogin from './game-stats-login';
import GameStats from './game-stats';
import UserStats from './user-stats';

class GameStatsController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isCheckingLogin: true,
			isLoading: false,
			isLoggedIn: false,
			email: null,
			password: null,
			feedback: null,
			users: [],
			areasData: [],
			missionsData: [],
			surveysData: [],
			sortProperty: 'id',
			sortDirection: 'ASC',
			userId: null,
			authSessionData: {
				userId: null,
				userTokenId: null,
				email: null,
			}
		};
		this.unsubscribeSnapshot = null;
		this.unsubscribeOnAuthStateChanged = null;
		this.unsubscribeUserStats = null;
	};

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		/* Load game content */
		const db = firebase.firestore();
		Promise.all([
			loadAreasData(db),
			loadMissionsData(db), 
			loadSurveysData(db), 
			this.checkIfLoggedIn()

		]).then((responses) => {
			let areasData = responses[0].areasData;
			let missionsData = responses[1].missionsData;
			let surveysData = responses[2].surveysData;

			this.setState({isLoading: false, areasData, missionsData, surveysData });
		});
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.unsubscribeSnapshot !== null) this.unsubscribeSnapshot();
		if (this.unsubscribeOnAuthStateChanged !== null) this.unsubscribeOnAuthStateChanged();
	}

	/**
	 * Subscribe to login status
	 */
	checkIfLoggedIn = () => {
		this.setState({isCheckingLogin: true});

		// Unsubscribe previous onAuthStateChanged
		if (this.unsubscribeOnAuthStateChanged !== null) {
			this.unsubscribeOnAuthStateChanged();
		}

		// Subscribe to onAuthStateChanged
		this.unsubscribeOnAuthStateChanged = firebase.auth().onAuthStateChanged((user)=>{
			if (user && user.email) {
				user.getIdToken().then((idToken) => {
					this.setState({
						isCheckingLogin: false,
						isLoggedIn: true,
						isLoading: true,
						authSessionData: {
							userId: user.uid,
							userTokenId: idToken,
							email: user.email
						}
					}, () => {
						this.subscribeToUserStats().then(() => {
							this.setState({isLoading: false});
						}, (error) => {
							console.error(error);
							this.setState({isLoading: false});
						});
					});
				});
			} else if (user) {
				this.handleLogout();
			}
		});
	}


	/**
	 * Update input field
	 * @param {obj} event 
	 */
	handleInput = (event) => {
		let value = event.target.value;
		let name = event.target.name;
		this.setState({
			[name]: value,
			feedback: null
		});
	}
	
	/**
	 * Handle login
	 * @param {obj} event 
	 */
	handleLogin = (event) => {
		event.preventDefault();
		this.setState({isLoading: true});
		let email = this.state.email;
		let password = this.state.password;
		
		let error = null;
		if (!email || !password) error = 'empty-fields';
		if (!error) {
			firebase.auth().signInWithEmailAndPassword(email, password).catch((error) => {
				let errorMsg = error.code;
				this.setState({
					feedback: errorMsg,
					isLoading: false
				});
			});
		} else {
			const msg = error;
			this.setState({
				feedback: msg,
				isLoading: false
			});
		}
	}	

	/**
	 * Log out
	 */
	handleLogout = () => {
		firebase.auth().signOut();
		this.setState({
			isLoggedIn: false
		});
	}

	/**
	 * Subscribe to user data
	 */
	subscribeToUserStats = () => {
		if (this.unsubscribeUserStats !== null) this.unsubscribeUserStats();
		const db = firebase.firestore();
		return new Promise((resolve, reject)=>{
			this.unsubscribeUserStats = db.collection(appConfig.usersDbName).onSnapshot((querySnapshot) => {
				let users = [];
				if (!querySnapshot.empty) {
					users = querySnapshot.docs.map((doc) => {
						let data = doc.data();
						data.id = doc.id;
						return data;						
					});
				}
				this.setState({users: users}, () => {resolve({status: 'ok'});});

			}, (error) => {reject({status: 'error', error: error});});
		});
	}

	/**
	 * Sort data
	 * @param {string} sortProperty 
	 * @param {string} sortDirection 
	 */
	handleSortBy = (sortProperty, sortDirection) => {
		this.setState({sortProperty, sortDirection});
	}

	/**
	 * Select user
	 * @param {string} userId 
	 */
	setUserId = (userId) => {
		this.setState({userId: userId});
	}

	/**
	 * Render component
	 */
	render = () => {
		/* Login page */
		if (!this.state.isLoggedIn) {
			return (
				<GameStatsLogin 
					isLoading={this.state.isLoading}
					email={this.state.email}
					password={this.state.password}
					feedback={this.state.feedback}
					handleInput={this.handleInput}
					handleLogin={this.handleLogin}
				/>
			);
		}

		/* Specific user data */
		if (this.state.userId) {
			let userObj = null;
			if (this.state.users && this.state.users.some((user) => {return user.id === this.state.userId;})) {
				userObj = this.state.users.filter((user) => {return user.id === this.state.userId;})[0];
			}
			return (
				<UserStats 
					userObj={userObj} 
					setUserId={this.setUserId} 
					areasData={this.state.areasData}
					missionsData={this.state.missionsData}
					surveysData={this.state.surveysData}
				/>
			);
		}

		/* General game data */
		let users = sortArrayByProperty(this.state.users, this.state.sortProperty, this.state.sortDirection);
		return (
			<GameStats 
				isLoading={this.state.isLoading}
				users={users}
				sortProperty={this.state.sortProperty}
				sortDirection={this.state.sortDirection}
				setUserId={this.setUserId}
				handleSortBy={this.handleSortBy}
				handleLogout={this.handleLogout}
			/>
		);
	}
};

export default GameStatsController;