import React from 'react';
import PropTypes from 'prop-types';
import Portal from './portal';
import PortalFeedback from './portal-feedback';

class PortalController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			mazeId: this.props.playerData.mazeId,
			keys: 0,
			unlockedKeys: 0,
			animateKey: false,
			animatePortal: false,
			isEndOfGame: false,
			portalIsOpen: false,
			showNextBtn: false,
			showLockOpenedText: false,
			showPortalOpenedText: false,
			showPortalFeedback: false
		};
		this.timeout = null;
		this.timeout2 = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		let areaData = this.props.areasData.find((maze) => {return maze.id === this.props.playerData.mazeId;});

		let keys = null;
		if (areaData.hasOwnProperty('portal') && areaData.portal.keys) keys = areaData.portal.keys;
	
		let unlockedKeys = this.props.playerData.missionsCompleted.filter((mission) => {
			return (mission.mazeId === areaData.id);
		}).length;

		let animateKey = true; // this.props.animateKey;
		let animatePortal = (animateKey && keys === unlockedKeys);
		let isEndOfGame = (animatePortal && areaData && areaData.portal && areaData.portal.nextAreaId === 'exit');

		if (areaData && areaData.portal && areaData.portal.nextAreaId && animatePortal) {
			if (isEndOfGame) {
				this.props.updatePlayerData({gameEnded: true, lockedCards: []});
			} else {
				this.props.updatePlayerData({mazeId: areaData.portal.nextAreaId});
			}
		}

		this.setState({mazeId: areaData.id, keys, unlockedKeys, animateKey, isEndOfGame}, () => {
			this.timeout = setTimeout(() => {
				let showNextBtn = (animateKey === true && animatePortal === false);
				this.setState({animateKey: false, animatePortal, showNextBtn});
			}, 3000);
			if (animatePortal) {
				this.timeout = setTimeout(
					() => {this.setState({animatePortal: false, portalIsOpen: true, showNextBtn: true});}
					, 5000
				);
			}
		});
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.timeout) clearTimeout(this.timeout);
		if (this.timeout2) clearTimeout(this.timeout2);
	}

	showPortalFeedback = () => {
		this.setState({showPortalFeedback: true});
	}

	/**
	 * Render component
	 */
	render() {
		if (!this.state.showPortalFeedback) {
			return (
				<Portal 
					mazeId={this.state.mazeId}
					keys={this.state.keys}
					unlockedKeys={this.state.unlockedKeys}
					animateKey={this.state.animateKey}
					animatePortal={this.state.animatePortal}
					portalIsOpen={this.state.portalIsOpen}
					isEndOfGame={this.state.isEndOfGame}
					showNextBtn={this.state.showNextBtn}
					showPortalFeedback={this.showPortalFeedback}
					handleClosePortalPopup={this.props.handleClosePortalPopup}
				/>
			);
		}

		return (
			<PortalFeedback
				portalIsOpen={this.state.portalIsOpen}
				handleClosePortalPopup={this.props.handleClosePortalPopup}
			/>
		);
	}
}

PortalController.propTypes = {
	areasData: PropTypes.array.isRequired,
	playerData: PropTypes.object.isRequired,
	updatePlayerData: PropTypes.func.isRequired,
	handleClosePortalPopup: PropTypes.func.isRequired
};

export default PortalController;