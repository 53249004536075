import appConfig from 'config/app.config';

/**
 * Get time played
 * @param {number} startTimeSecs 
 * @param {bool} isQuickGame 
 */
function getTimePlayed(startTimeSecs, isQuickGame) {
	let gameDuration = (startTimeSecs !== null  
		? (Math.floor(new Date().getTime() / 1000) - startTimeSecs)
		: 0
	);
	if (isQuickGame) gameDuration = gameDuration * 10;
	return gameDuration;
}

/**
 * Get available cards in current deck
 * @param {bool} isFreeplay
 * @param {array} decksData
 * @param {string} deckId 
 * @param {number} mazeId
 * @param {array} lockedCards
 * @param {array} cardDetention 
 */
function getAvailableCardsInDeck(isFreeplay, decksData, deckId, mazeId, lockedCards, cardDetention) {
	let deck = (decksData.some((deck) => {return deck.id === deckId;})
		? decksData.filter((deck) => {return deck.id === deckId;})[0]
		: null
	);

	let availableCards = deck.cards.filter((card) => {
		return (
			card.isRandom === true && 
			lockedCards.indexOf(card.id) < 0 && 
			!cardDetention.some((dCard) => {return dCard.id === card.id;}) &&
			(
				isFreeplay || 
				deckId !== 'basic' || !card.mazeId || 
				(card.mazeId <= mazeId && (!card.mazeIdMax || card.mazeIdMax >= mazeId))
			) 
		);
	});

	return availableCards;
}

/**
 * Check if dream has ended
 * @param {number} startTimeSecs 
 * @param {bool} isQuickGame 
 * @param {object} selectedOption 
 * @param {string} deckId 
 */
function getIsEndOfDream(timePlayedSecs, selectedOption, deckId, availableCards) {
	let isEndOfDream = false;

	/* Next card is random, check time played and deck */
	let nextCardIsRandom = (selectedOption.nextCard.type === 'random');
	if (!isEndOfDream && nextCardIsRandom) {
		/* Not in special deck and not going to special deck */
		if (deckId !== 'party' && selectedOption.nextCard.deckId !== 'party') {
			isEndOfDream = (timePlayedSecs >= appConfig.gameDurationLimitSecs);
		}

		/* Returning to basic deck */
		if (deckId !== 'basic' && selectedOption.nextCard.deckId === 'basic') {
			if (deckId === 'forest' || deckId === 'party') { // TODO: why these conditions?
				isEndOfDream = (timePlayedSecs >= appConfig.gameDurationSoftLimitSecs);
			}
		}
	}

	/* In basic deck and no more cards left */
	if (!isEndOfDream && nextCardIsRandom && selectedOption.nextCard.deckId === 'basic' && availableCards.length < 1) {
		isEndOfDream = true;
	}

	return isEndOfDream;
}



export {
	getTimePlayed,
	getAvailableCardsInDeck,
	getIsEndOfDream
};