import React from 'react';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
// import statsData from 'data/stats-data';
import {getCurrentDate} from 'helpers/date-helper';
import {getTimePlayed, getAvailableCardsInDeck, getIsEndOfDream} from 'helpers/game-helper';
import ProgressController from 'components/progress/progress-controller';
import MazeGame from './maze-game';
import PortalController from 'components/portal/portal-controller';
import SurveyController from 'components/survey/survey-controller';
import Overview from 'components/overview/overview';
import MainMenu from 'components/main-menu/main-menu';
import TheEnd from 'components/the-end/the-end';

class MazeGameController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isFreeplay: false,
			startTimeSecs: null,
			pauseTimeSecs: null,
			// playerStats: {},
			areaData: null,
			deckId: null,
			cardId: null,
			showProgress: false,
			selectedOption: null,
			missionId: null,
			surveyId: null,
			isEndOfDream: false,
			showFeedback: false,
			showMissionCompletedFeedback: false,
			showPortal: false,			
			portalIsOpen: false,
			showSurvey: false,
			showEndOfDreamCard: false,
			isGameover: false,
			showMainMenu: false,
			showTheEndPopup: false
		};
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		this.startNewGame();
	}

	/**
	 * Start new game
	 */
	startNewGame = () => {
		/* Reset player stats */
		// let playerStats = {};
		// statsData.stats.forEach((stat) => {playerStats[stat.id] = stat.initialValue;});
		
		/* Get area data */
		let areaData = this.props.areasData.find((maze) => {return maze.id === this.props.playerData.mazeId;});
				
		/* Show onboarding deck or draw random card from morning deck */
		let deckId = 'onboarding';
		let cardId = 'onboarding-1';
		if (this.props.playerData.onboardingPlayed) {
			deckId = 'morning';
			if (this.state.portalIsOpen) deckId = 'morning2';
			cardId = this.pickRandomCard(deckId); 
		}
		
		/* Game start time */
		let startTimeSecs = Math.floor(new Date().getTime() / 1000);

		/* Check if freeplay */
		let isFreeplay = false;
		if (this.props.playerData.gameEnded) isFreeplay = true;

		/* Play count and card detention */
		let playCount = this.props.playCount + 1;
		let cardDetention = JSON.parse(JSON.stringify(this.props.cardDetention));
		cardDetention = cardDetention.filter((card) => {
			return ((playCount - card.playCount) < appConfig.cardDetentionDuration);
		});
		this.props.updatePlayCount(playCount);
		this.props.updateCardDetention(cardDetention);

		/* Update state */
		this.setState({
			/* Reset timer */
			showProgress: false
		}, () => {
			/* Start new game */
			this.setState({
				isFreeplay,
				startTimeSecs, 
				pauseTimeSecs: startTimeSecs, 
				deckId, 
				cardId, 
				// playerStats, 
				areaData, 
				showProgress: true,
				selectedOption: null,
				showFeedback: false,
				missionId: null,
				showMissionCompletedFeedback: false,
				surveyId: null,
				showSurvey: false,
				isGameover: false,
				isEndOfDream: false,
				showEndOfDreamCard: false,
				showPortal: false,
				showOverview: false,
				portalIsOpen: false,
				showMainMenu: false,
				animateMissionCompleted: false,
				showTheEndPopup: false
			});
		});
	}

	/**
	 * Hide / show main menu popup
	 */
	handleToggleMainMenu = () => {
		let showMainMenu = !this.state.showMainMenu;
		this.setState({showMainMenu});
	}
	
	/**
	 * Player selects card option (left / right OR button)
	 * @param {object} optionData 
	 */
	handleSelectOption = (optionData) => {
		/* Check if dream has ended */
		let isEndOfDream = (optionData.hasOwnProperty('nextCard')
			? getIsEndOfDream(
				getTimePlayed(this.state.startTimeSecs, this.props.isQuickGame), 
				optionData, 
				this.state.deckId, 
				getAvailableCardsInDeck(
					this.state.isFreeplay,
					this.props.decksData,
					optionData.nextCard.deckId, 
					this.props.playerData.mazeId,
					this.props.playerData.lockedCards,
					this.props.cardDetention
				)
			)
			: false
		);

		/* Check if a mission was completed */
		let missionId = null;
		if (optionData.mission) {
			let missionsCompleted = JSON.parse(JSON.stringify(this.props.playerData.missionsCompleted));
			if (
				!missionsCompleted.some((mission) => {return mission.id === optionData.mission.missionId;}) &&
				(
					optionData.mission.conditionType === 'none' ||
					(
						optionData.mission.conditionType === 'secret' && 
						this.props.playerData.secretsDiscovered.indexOf(optionData.mission.secretId) >= 0
					)
				)
			) {
				missionId = optionData.mission.missionId;
			}
		}

		/* Check if a survey was triggered */
		let surveyId = null;
		if (optionData.survey && optionData.survey.hasOwnProperty('isRandomSurvey')) {
			if (optionData.survey.isRandomSurvey === true) {
				surveyId = this.pickRandomSurvey(optionData.survey.surveyType);
			} else {
				if (optionData.survey.surveyId) surveyId = optionData.survey.surveyId;
			}
		}

		/* Update player data */
		this.handleUpdatePlayerData(optionData, missionId).then((response) => {
			/* Update state */
			this.setState({
				isEndOfDream: isEndOfDream,
				selectedOption: optionData,
				missionId: missionId,
				surveyId: surveyId,
				// playerStats: response.playerStats
			}, () => {
				/* Go to next game step */
				this.goToNextGameStep();
			});
		});
	}

	/**
	 * Update player data based on choice
	 * @param {object} optionData 
	 * @param {number} missionId
	 */
	handleUpdatePlayerData = (optionData, missionId) => {
		return new Promise((resolve)=>{
			/* Prepare player updates */
			let playerDataUpdates = null;

			/* Get stats data */
			// let playerStats = JSON.parse(JSON.stringify(this.state.playerStats));

			/* Update "number of games played" if just starting a game */
			if (this.state.deckId === 'morning') {
				let playDate = getCurrentDate();
				let gamesPlayed = JSON.parse(JSON.stringify(this.props.playerData.gamesPlayed));
				gamesPlayed.push(playDate);
				if (!playerDataUpdates) playerDataUpdates = {};
				playerDataUpdates.gamesPlayed = gamesPlayed;
			}

			/* Update player stats */
			// statsData.stats.forEach((stat) => {
			// 	if (optionData.stats.hasOwnProperty(stat.id)) {
			// 		playerStats[stat.id] = 
			// 			Math.min(playerStats[stat.id] + optionData.stats[stat.id], statsData.maxStatValue);
			// 	}
			// });

			/* Update discovered secrets */
			if (optionData.secretId) {
				if (this.props.playerData.secretsDiscovered.indexOf(optionData.secretId) < 0) {
					let secretsDiscovered = JSON.parse(JSON.stringify(this.props.playerData.secretsDiscovered));
					secretsDiscovered.push(optionData.secretId);
					if (!playerDataUpdates) playerDataUpdates = {};
					playerDataUpdates.secretsDiscovered = secretsDiscovered;
				}
			}

			/* Update completed missions */
			if (missionId) {
				let missionsCompleted = JSON.parse(JSON.stringify(this.props.playerData.missionsCompleted));
				missionsCompleted.push({id: missionId, mazeId: this.props.playerData.mazeId});
				if (!playerDataUpdates) playerDataUpdates = {};
				playerDataUpdates.missionsCompleted = missionsCompleted;

				/* Lock cards */
				if (!this.state.isFreeplay) {
					if (
						optionData.mission && optionData.mission.locksCards && 
						optionData.mission.locksCards.length > 0
					) {
						let lockedCards = JSON.parse(JSON.stringify(this.props.playerData.lockedCards));
						optionData.mission.locksCards.forEach((card) => {
							if (lockedCards.indexOf(card) < 0) lockedCards.push(card);
							playerDataUpdates.lockedCards = lockedCards;
						});
					}
				}
			}

			/* Update player data */
			if (playerDataUpdates) this.props.updatePlayerData(playerDataUpdates);
			// resolve({status: 'ok', playerStats});
			resolve({status: 'ok'});
		});	
	}

	/**
	 * Go to next game step
	 */
	goToNextGameStep = () => {
		/* Gameover */
		if (this.state.isGameover) {
			console.log('Warning: "Next step: is game over" triggered');
			this.setState({showMainMenu: true});
			return;
		}

		/* Show feedback */
		if (this.state.selectedOption && this.state.selectedOption.feedback && !this.state.showFeedback) {
			setTimeout(() => {this.setState({showFeedback: true});}, 500);
			return;
		}
		
		/* Show mission completed feedback */
		if (this.state.missionId !== null && !this.state.showMissionCompletedFeedback) {
			setTimeout(() => {
				let cardId = this.state.cardId;
				this.setState({cardId: null}, () => {
					this.setState({cardId: cardId, showMissionCompletedFeedback: true});
				});
			}, 500);
			return;
		}
		
		/* Show mission completed flow (maze -> portal) */
		if (
			!this.state.isFreeplay && 
			!this.state.showEndOfDreamCard && 
			this.state.missionId !== null && this.state.showMissionCompletedFeedback === true && 
			this.state.areaData.portal && this.state.showPortal === false
		) {
			setTimeout(() => {this.setState({showOverview: true, animateMissionCompleted: true});}, 500);
			setTimeout(() => {this.setState({showPortal: true});}, 6000);
			setTimeout(() => {this.setState({showOverview: false, animateMissionCompleted: false});}, 7000);
			return;
		}

		/* Portal unlocked */
		if (this.state.portalIsOpen) {
			if (this.props.playerData.gameEnded === true) {
				/* Finish maze */
				this.setState({isGameover: true, surveyId: 'exit', showSurvey: true});
			} else {
				/* Go to next area, end game */
				this.setState({isGameover: true, showMainMenu: true});
			}
			return;
		}

		/* End of dream */
		if (this.state.isEndOfDream) {
			if (!this.state.showEndOfDreamCard) {
				/* Show gameover card */
				setTimeout(() => {
					this.setState({cardId: null}, () => {
						this.setState({
							deckId: 'night',
							cardId: 'night-1', 
							showEndOfDreamCard: true,
							showPortal: false,
						});
					});
				}, 500);
				return;
			}

			/* Show gameover survey */
			let surveyId = (this.state.surveyId === null ? this.pickRandomSurvey('gameover') : this.state.surveyId);
			setTimeout(() => {this.setState({showSurvey: true, surveyId});}, 500);
			return;
		}

		/* Show survey related to selected option */
		if (this.state.surveyId !== null && !this.state.showSurvey) {
			setTimeout(() => {this.setState({showSurvey: true});}, 500);
			return;
		}

		/* Show next card */
		let deckId = this.state.selectedOption.nextCard.deckId;
		let nextCardType = this.state.selectedOption.nextCard.type;

		/* Mark onboarding as completed */
		if (deckId !== 'onboarding' && !this.props.playerData.onboardingPlayed) {
			this.props.updatePlayerData({onboardingPlayed: true});
		}

		/* Check if it is time for a pause card */
		let pauseTimeSecs = this.state.pauseTimeSecs;
		if (deckId === 'basic' && nextCardType === 'random') {
			let timeSinceLastPause = getTimePlayed(pauseTimeSecs, this.props.isQuickGame);
			let timeLeft = appConfig.gameDurationLimitSecs - 
				getTimePlayed(this.state.startTimeSecs, this.props.isQuickGame);
			if (timeSinceLastPause > appConfig.pauseIntervalSecs && timeLeft > 60) {
				deckId = 'pause';
				nextCardType = 'random';
				pauseTimeSecs = Math.floor(new Date().getTime() / 1000);
			}
		} 

		/* Get card id */
		let cardId = (nextCardType === 'random' 
			? this.pickRandomCard(deckId) 
			: this.state.selectedOption.nextCard.cardId
		);

		/* Update state */
		let updateStateData = {
			pauseTimeSecs,
			cardId, 
			deckId, 
			selectedOption: null, 
			missionId: null,
			surveyId: null,
			showFeedback: false,
			showMissionCompletedFeedback: false,
			showSurvey: false,
			showPortal: false,
			showMainMenu: false,
			showOverview: false,
			animateMissionCompleted: false,
		};
		setTimeout(() => {
			this.setState({cardId: null}, () => {
				this.setState(updateStateData);
			});
		}, 500);
	}

	/**
	 * Pick a random card from the deck
	 * @param {string} deckId 
	 */
	pickRandomCard = (deckId) => {		
		/* Get available cards from deck */
		let lockedCards = JSON.parse(JSON.stringify(this.props.playerData.lockedCards));
		let cardDetention = JSON.parse(JSON.stringify(this.props.cardDetention));
		let availableCards = getAvailableCardsInDeck(
			this.state.isFreeplay,
			this.props.decksData, 
			deckId, 
			this.props.playerData.mazeId,
			lockedCards, 
			cardDetention
		);

		/* If no cards available, flush cardDetention before getting available cards from deck */
		/* TODO: disable this when we have enough cards */
		/* NOTE: this is triggered inside a loop, if disabled we should handle loops that run out */
		if (availableCards.length === 0) {
			cardDetention = [];
			availableCards = getAvailableCardsInDeck(
				this.state.isFreeplay,
				this.props.decksData, 
				deckId, 
				this.props.playerData.mazeId, 
				lockedCards,
				[]
			);
		}

		/* Get random card from available cards */
		let randomIndex = Math.floor(Math.random() * availableCards.length);
		let cardId = availableCards[randomIndex].id;

		/* Update card detention */
		if (deckId !== 'pause') {
			cardDetention.push({id: cardId, playCount: this.props.playCount});
			this.props.updateCardDetention(cardDetention);
		}
		return cardId;
	}

	/**
	 * Close portal popup
	 * @param {bool} portalIsOpen 
	 */
	handleClosePortalPopup = (portalIsOpen) => {
		this.setState({portalIsOpen}, () => {
			this.goToNextGameStep();
		});
	}

	/**
	 * Pick a random survey of a specific type
	 * @param {string} surveyType 
	 */
	pickRandomSurvey = (surveyType) => {
		let availableSurveys = this.props.surveysData.filter((survey) => {
			return (
				survey.surveyType === surveyType && 
				(surveyType !== 'gameover' || survey.mazeId <= this.props.playerData.mazeId)
			);
		});
		if (availableSurveys.length > 0) {
			let randomIndex = Math.floor(Math.random() * availableSurveys.length);
			let surveyId = availableSurveys[randomIndex].id;
			return surveyId;
		} 
		return null;
	}

	/**
	 * Close survey popup
	 */
	handleCompleteSurvey = (surveyData) => {
		if (surveyData) {
			/* Save survey response */
			let surveys = (this.props.playerData.hasOwnProperty('surveys') 
				? JSON.parse(JSON.stringify(this.props.playerData.surveys)) 
				: []
			);
			surveyData.mazeId = this.props.playerData.mazeId;
			surveys.push(surveyData);
			this.props.updatePlayerData({surveys: surveys});	

			/* Finish game */
			if (this.props.playerData.gameEnded === true && surveyData.id === 'exit') {
				this.setState({isEndOfDream: true, isGameover: true, showTheEndPopup: true});
			} else {
				/* End game or go to next game step */
				if (this.state.isEndOfDream) {
					this.setState({showMainMenu: true, isGameover: true});
				} else {
					this.goToNextGameStep();
				}			
			}
		} else {
			/* Unknown survey, go to next step */
			this.goToNextGameStep();
		}
	}

	/**
	 * Close the end popup, finish game
	 */
	handleCloseTheEndPopup = () => {
		this.setState({showTheEndPopup: false, showMainMenu: true});
	}

	/**
	 * Render component
	 */
	render() {
		let decksData = this.props.decksData;

		let deckData = null, cardData = null;
		if (decksData.some((deck) => {return deck.id === this.state.deckId;})) {
			deckData = decksData.filter((deck) => {return deck.id === this.state.deckId;})[0];
			if (deckData.cards.some((card) => {return card.id === this.state.cardId;})) {
				cardData = deckData.cards.filter((card) => {return card.id === this.state.cardId;})[0];
			}
		}

		let completedMission = null;
		if (this.state.showMissionCompletedFeedback && 
			this.props.missionsData.some((mission) => {return mission.id === this.state.missionId;})
		) {
			completedMission = this.props.missionsData.find((mission) => {return mission.id === this.state.missionId;});
		}

		return (
			<React.Fragment>
				<MazeGame
					showEndOfDreamCard={this.state.showEndOfDreamCard}
					playerData={this.props.playerData}
					// playerStats={this.state.playerStats}
					cardData={cardData}
					selectedOption={this.state.selectedOption}
					showFeedback={this.state.showFeedback}
					missionData={completedMission} 
					showMissionCompletedFeedback={this.state.showMissionCompletedFeedback}
					handleSelectOption={this.handleSelectOption}
					goToNextGameStep={this.goToNextGameStep}
					handleToggleMainMenu={this.handleToggleMainMenu}
				/>
				{this.state.showProgress && <ProgressController 
					hideProgress={false} 
					isFreeplay={this.state.isFreeplay}
					showPortal={this.state.showPortal}
					isQuickGame={this.props.isQuickGame}	
					areasData={this.props.areasData}
					playerData={this.props.playerData}
				/>}

				{this.state.showOverview &&
					<Overview 
						animateMissionCompleted={this.state.animateMissionCompleted}
						animatePortalIsOpen={this.state.portalIsOpen}
						areasData={this.props.areasData}
						playerData={this.props.playerData}
						missionId={this.state.missionId}
						missionsData={this.props.missionsData}
					/>
				}
				{this.state.showPortal && 
					<PortalController 
						areasData={this.props.areasData}
						playerData={this.props.playerData}
						updatePlayerData={this.props.updatePlayerData}
						handleClosePortalPopup={this.handleClosePortalPopup}
					/>
				}
				{(this.state.surveyId && this.state.showSurvey) && 
					<SurveyController 
						surveyId={this.state.surveyId} 
						surveysData={this.props.surveysData}
						handleCompleteSurvey={this.handleCompleteSurvey}
					/>
				}

				{this.state.showTheEndPopup && 
					<TheEnd handleCloseTheEndPopup={this.handleCloseTheEndPopup} />
				}

				{this.state.showMainMenu &&
					<MainMenu
						animateSlideIn={false}
						isLoading={false}
						isPlaying={true}
						isGameover={this.state.isGameover}
						playerData={this.props.playerData}
						decksData={this.props.decksData}
						missionsData={this.props.missionsData}
						handleContinueGame={(this.state.isGameover ? this.startNewGame : this.handleToggleMainMenu)}
					/>
				}
			</React.Fragment>
		);
	}
}

MazeGameController.propTypes = {
	isQuickGame: PropTypes.bool.isRequired,
	areasData: PropTypes.array.isRequired,
	decksData: PropTypes.array.isRequired,
	missionsData: PropTypes.array.isRequired,
	surveysData: PropTypes.array.isRequired,
	playCount: PropTypes.number.isRequired,
	cardDetention: PropTypes.array.isRequired,
	playerData: PropTypes.object.isRequired,
	updatePlayerData: PropTypes.func.isRequired,
	updateCardDetention: PropTypes.func.isRequired,
	updatePlayCount: PropTypes.func.isRequired
};

export default MazeGameController;