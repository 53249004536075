import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import PortalMini from 'components/portal-mini/portal-mini';
import './overview.scss';

const Overview = (props) => {
	let {
		animateMissionCompleted,
		playerData, 
		missionId,
		areasData
	} = props;


	/* Show key animation */
	const [scrollToCurrentMaze, setScrollToCurrentMaze] = useState(false);
	const [showKeyAnimation, setShowKeyAnimation] = useState(false);
	const [showKeyUnlocked, setShowKeyUnlocked] = useState(false);


	/* Component did mount */
	useEffect(() => {
		/* Scroll to current maze */
		setTimeout(() => {setScrollToCurrentMaze(true);}, 50);

		/* Animate mission completed */
		if (animateMissionCompleted) {
			setTimeout(() => {setShowKeyAnimation(true);}, 1500);
			setTimeout(() => {setShowKeyUnlocked(true);}, 4550);
		}
	});
	
	return (
		<div className="Overview">
			{/* Maze progress */}
			<div className={'Overview-mazes' + (scrollToCurrentMaze ? ' Overview-mazes--' + playerData.mazeId : '')}>
				{areasData.map((areaData, index) => {
					if (index > 4) return null; // NOTE: layout only fits 5 mazes
					let mazeImg = null;
					try {
						mazeImg = require('../../assets/images/mazes/' + areaData.imageFile);	
					} catch (error) {
						console.error(error);
					}
					
					let unlockedKeys = playerData.missionsCompleted.filter((mission) => {
						return (
							mission.mazeId === areaData.id && 
							(
								missionId !== mission.id ||
								!animateMissionCompleted || 
								showKeyUnlocked
							)
						);
					});
					return (
						<div key={areaData.id} id={'maze-' + areaData.id} className={'Overview-maze'}>
							{mazeImg && <img src={mazeImg} alt="maze" />}
							{areaData.portal && 
								<PortalMini 
									type="overview" 
									keys={areaData.portal.keys} 
									unlockedKeys={unlockedKeys.length} 
								/>
							}
						</div>
					);
				})}
			</div>
			<div className={'Overview-keyAnimation2' + (showKeyAnimation ? ' show' : '')} />
		</div>		
				
	);
};

Overview.defaultPropTypes = {
	animatePortalIsOpen: false,
	missionId: null
};

Overview.propTypes = {
	animateMissionCompleted: PropTypes.bool.isRequired,
	playerData: PropTypes.object.isRequired,
	areasData: PropTypes.array.isRequired,
	missionId: PropTypes.number
};

export default Overview;