import React, {useState} from 'react';
import PropTypes from 'prop-types';
import './login.scss';
import {loginUiTexts} from 'data/ui-texts';

const Login = (props) => {
	let {
		isLoading,
		isLoggingIn, 
		loginPageClass,
		isCreatingUser,
		username, 
		password, 
		password1, 
		password2, 
		feedback, 
		feedback2, 
		handleInput, 
		handleLogin,
		handleCreateUser
	} = props;

	let [showCreateUser, setShowCreateUser] = useState(false); 

	return (
		<div className={'Login' + (loginPageClass.length > 0 ? ' Login--' + loginPageClass : '')}>
			{!showCreateUser && <form className="Login-form" onSubmit={handleLogin}>
				<input
					name="username"
					type="text"
					placeholder={loginUiTexts.namePlaceholder}
					autoComplete="section-userlogin username"
					value={username ? username : ''} 
					onChange={(event)=>{handleInput(event);}}
				/>
				<input
					name="password"
					type="password"
					placeholder={loginUiTexts.passwordPlaceholder}
					autoComplete="section-userlogin password"
					value={password ? password : ''} 
					onChange={(event)=>{handleInput(event);}}
				/><br />
				<div 
					className="Login-createUserBtn" 
					onClick={() => {setShowCreateUser(true);}}>{loginUiTexts.createNewPlayer}</div>
				<div 
					className={'Login-loginBtn' + (isLoggingIn || isLoading ? ' loading' : '')} 
					onClick={(event)=>{handleLogin(event);}}
				><span>{loginUiTexts.login}</span></div>
				<div className={'Login-errorMessage'}><span>{feedback}</span></div>

			</form>}

			{showCreateUser && <form className="Login-form create">
				<input
					name="username"
					type="text"
					placeholder={loginUiTexts.namePlaceholder}
					autoComplete="section-createUser username"
					value={username ? username : ''} 
					onChange={(event)=>{handleInput(event);}}
				/>
				<input
					name="password1"
					type="password"
					placeholder={loginUiTexts.passwordPlaceholder}
					autoComplete="section-createUser password"
					value={password1 ? password1 : ''} 
					onChange={(event)=>{handleInput(event);}}
				/><br />
				<input
					name="password2"
					type="password"
					placeholder={loginUiTexts.passwordRepeatPlaceholder}
					autoComplete="section-createUser password"
					value={password2 ? password2 : ''} 
					onChange={(event)=>{handleInput(event);}}
				/><br />
				<div 
					className={'Login-loginBtn' + (isCreatingUser ? ' loading' : '')} 
					onClick={()=>{handleCreateUser();}}
				><span>{loginUiTexts.login}</span></div>
				<div className={'Login-errorMessage'}><span>{feedback2}</span></div>
				<div 
					className="Login-createUserBtn" 
					onClick={() => {setShowCreateUser(false);}}>{loginUiTexts.backToLogin}</div>
			</form>}
		</div>
	);
};

Login.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	loginPageClass: PropTypes.string.isRequired,
	isLoggingIn: PropTypes.bool.isRequired,
	isCreatingUser: PropTypes.bool.isRequired,
	username: PropTypes.string,
	password: PropTypes.string,
	password1: PropTypes.string,
	password2: PropTypes.string,
	feedback: PropTypes.string,
	feedback2: PropTypes.string,
	handleInput: PropTypes.func.isRequired,
	handleLogin: PropTypes.func.isRequired,
	handleCreateUser: PropTypes.func.isRequired
};

export default Login;