import React, {Component} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import PropTypes from 'prop-types';
import appConfig from 'config/app.config';
import {getImageUrl} from 'helpers/storage-helper';
import {sortArrayByProperty} from 'helpers/array-helper';
import {getSurveyUpdates, getSurveyTemplate, getStepTemplate} from 'helpers/admin-helper';
import Surveys from './surveys';

class SurveysController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			errmsg: null,
			surveys: [],
			cards: [],
			images: [],
			selectedSurvey: null,
			surveyPreviewId: null,
			isEditing: false,
			isSaving: false,
			isCreating: false
		};
		this.unsubscribeSurveys = null;
		this.unsubscribeCards = null;
		this.unsubscribeUploadedImages = null;
	};

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		Promise.all([
			this.subscribeToSurveys(), 
			this.subscribeToCards(),
			this.subscribeToUploadedImages()
		]).then(() => {
			this.setState({isLoading: false});
		}).catch((error) => {
			console.error(error);
			this.setState({isLoading: false, errmsg: error});
		});
	}

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.unsubscribeSurveys) this.unsubscribeSurveys();
		if (this.unsubscribeCards) this.unsubscribeCards();
		if (this.unsubscribeUploadedImages) this.unsubscribeUploadedImages();
	}

	/**
	 * Subscribe to surveys
	 */
	subscribeToSurveys = () => {
		if (this.unsubscribeSurveys !== null) this.unsubscribeSurveys();
		const db = firebase.firestore();
		return new Promise((resolve)=>{
			this.unsubscribeSurveys = db.collection(appConfig.surveysDbName).onSnapshot((querySnapshot) => {
				let surveys = [];
				if (!querySnapshot.empty) {
					surveys = querySnapshot.docs.map((doc) => {
						let data = doc.data();
						data.id = doc.id;
						data.index = parseInt(doc.id.split('-').length > 1 ? doc.id.split('-')[1] : null);
						return data;						
					});
					surveys = sortArrayByProperty(surveys, 'index', 'ASC');
				}
				this.setState({surveys: surveys}, () => {resolve({status: 'ok'});});
			}, (error) => {resolve({status: 'error', error: error});});
		});
	}

	/**
	 * Subscribe to cards
	 */
	subscribeToCards = () => {
		if (this.unsubscribeCards !== null) this.unsubscribeCards();
		const db = firebase.firestore();
		return new Promise((resolve)=>{
			this.unsubscribeCards = db.collection(appConfig.cardsDbName).onSnapshot((querySnapshot) => {
				let cards = [];
				if (!querySnapshot.empty) {
					cards = querySnapshot.docs.map((doc) => {
						let data = doc.data();
						data.id = doc.id;
						data.index = parseInt(doc.id.split('-').length > 1 ? doc.id.split('-')[1] : null);
						return data;						
					});
				}
				this.setState({cards: cards}, () => {resolve({status: 'ok', cards: cards});});
			}, (error) => {resolve({status: 'error', error: error});});
		});
	}

	/**
	 * Subscribe to images
	 */
	subscribeToUploadedImages = () => {
		return new Promise((resolve)=>{
			if (this.unsubscribeUploadedImages !== null) this.unsubscribeUploadedImages();
			const db = firebase.firestore();	
			this.unsubscribeUploadedImages = db.collection(appConfig.imagesDbName).onSnapshot((querySnapshot) => {
				let images = [];
				querySnapshot.docs.forEach((doc) => {
					let data = doc.data();
					data.id = data.fileName;
					data.title = data.fileName;
					data.value = data.fileName;
					images.push(data);
				});
				
				if (images.length > 0) {
					let promises = [];
					images.forEach((image) => {promises.push(getImageUrl(image.location, image.fileName));});
					Promise.all(promises).then(
						(response) => {
							response.forEach((imgData, index) => {
								if (imgData && imgData.hasOwnProperty('fileName') && imgData.hasOwnProperty('url')) {
									images[index].url = imgData.url;
								} else {
									images[index].url = null;
									console.error(imgData);
								}
							});
							this.setState({images: images}, () => {resolve({status: 'ok'});});
						});
				} else {resolve();}
			}, (error) => {
				console.error(error);
				resolve({status: 'error', error: error});
			});
		});
	}

	/**
	 * Select card
	 * @param {string} surveyId 
	 */
	selectSurvey = (surveyId) => {
		let selectedSurvey = this.state.surveys.find((survey) => {return survey.id === surveyId;});
		if (!surveyId || !selectedSurvey) {
			this.setState({selectedSurvey: null, isEditing: false});
		} else {
			this.setState({selectedSurvey});
		}
	}

	/**
	 * Update survey
	 * @param {object} event 
	 * @param {string} stepIndex 
	 */
	updateSurvey = (event, stepIndex = null, optionIndex = null) => {
		if (this.state.isSaving) return;
		let name = event.target.name;
		let value = event.target.value;
		let selectedSurvey = JSON.parse(JSON.stringify(this.state.selectedSurvey));
		if (stepIndex !== null) {
			/* Update survey step */
			if (selectedSurvey.steps.length > stepIndex) {
				if (name !== 'options') {
					selectedSurvey.steps[stepIndex][name] = value;
				} else {
					if (optionIndex !== null) {
						if (
							!selectedSurvey.steps[stepIndex].options || 
							selectedSurvey.steps[stepIndex].options.length === 0
						) {
							selectedSurvey.steps[stepIndex].options = ['', '', '', '', '', '', '', '', ''];
						}
						selectedSurvey.steps[stepIndex].options[optionIndex] = {title: value};
					}
				}
				
			}
		} else {
			/* Update survey */
			if (name === 'mazeId') {
				selectedSurvey[name] = parseInt(value);
			} else {
				selectedSurvey[name] = value;
			}
		}
		this.setState({selectedSurvey, isEditing: true});
	}

	/**
	 * Save changes
	 */
	saveSurvey = () => {
		/* Save survey updates */
		if (this.state.selectedSurvey && (this.state.isEditing || this.state.isCreating) && !this.state.isSaving) {
			this.setState({isSaving: true}, () => {
				let surveyUpdates = getSurveyUpdates(this.state.selectedSurvey);
				const db = firebase.firestore();
				db.collection(appConfig.surveysDbName).doc(this.state.selectedSurvey.id)
					.update(surveyUpdates).then(() => {
						this.setState({isEditing: false, isSaving: false, isCreating: false});
						this.selectSurvey(this.state.selectedSurvey.id);
					}, (error) => {
						console.error(error);
						this.setState({isSaving: false, isCreating: false});
					});
			});
		}
	}

	/**
	 * Show / hide survey preview
	 * @param {null|string} surveyId 
	 */
	showSurveyPreview = (surveyId) => {
		this.setState({surveyPreviewId: surveyId});
	}


	/**
	 * Create new survey
	 */
	createNewSurvey = () => {
		if (this.state.isCreating) return;

		this.setState({isCreating: true}, () => {
			/* Generate survey id */
			let surveyIndex = 1;
			let surveys = sortArrayByProperty(this.state.surveys, 'id', 'DESC');
			if (this.state.surveys.length > 0) {
				surveyIndex = (surveys[0].id.split('-').length > 1 
					? parseInt(surveys[0].id.split('-')[1]) + 1
					: (surveys.length + 1)
				);
			}

			/* Check card id does not already exist */
			let surveyIdExists = true;
			let numberOfIterations = 0;
			while (surveyIdExists && numberOfIterations < 1000) {
				numberOfIterations += 1;
				let surveyId = 'survey-' + surveyIndex;
				surveyIdExists = surveys.some((survey) => {return survey.id === surveyId;});
				if (surveyIdExists) surveyIndex += 1;
			}

			if (!surveyIdExists) {
				/* Create new card */
				let surveyId =  'survey-' + surveyIndex;
				let newSurveyObj = getSurveyTemplate(surveyId);
				const db = firebase.firestore();
				db.collection(appConfig.surveysDbName).doc(surveyId).set(newSurveyObj).then(() => {
					this.setState({isCreating: false});
				}, (error) => {
					console.error(error);
					this.setState({isCreating: false});
				});
			} else {
				console.log('Error creating card');
			}
		});
	}

	/**
	 * Create new survey step
	 */
	createNewStep = () => {
		if (this.state.isCreating || this.state.isSaving || this.state.isEditing) return;
		this.setState({isCreating: true}, () => {
			let selectedSurvey = JSON.parse(JSON.stringify(this.state.selectedSurvey));
			let newStepObj = getStepTemplate(selectedSurvey.steps.length);
			selectedSurvey.steps.push(newStepObj);
			this.setState({selectedSurvey, isEditing: true, isCreating: false});
		});
	}

	/**
	 * Render component
	 */
	render = () => {
		let selectedSurvey = this.state.selectedSurvey;
		if (this.state.surveyPreviewId !== null) {
			selectedSurvey = this.state.surveys.find((survey) => {return survey.id === this.state.surveyPreviewId;});
		}
		
		return (
			<Surveys 
				isEditing={this.state.isEditing}
				isSaving={this.state.isSaving}
				isCreating={this.state.isCreating}
				surveys={this.state.surveys}
				cards={this.state.cards}
				images={this.state.images}
				selectedSurvey={selectedSurvey}
				surveyPreviewId={this.state.surveyPreviewId}
				selectSurvey={this.selectSurvey}
				updateSurvey={this.updateSurvey}
				saveSurvey={this.saveSurvey}
				showSurveyPreview={this.showSurveyPreview}
				createNewSurvey={this.createNewSurvey}
				createNewStep={this.createNewStep}
				goToPage={this.props.goToPage}
			/>
		);
	}
};

SurveysController.propTypes = {
	goToPage: PropTypes.func.isRequired
};

export default SurveysController;