import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase: test */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyDp3iM3cUY3rLCtLdWrV3OUV8vfZzVHnXo',
		authDomain: 'cgl-mazeout-test.firebaseapp.com',
		databaseURL: 'https://cgl-mazeout-test.firebaseio.com',
		projectId: 'cgl-mazeout-test',
		storageBucket: 'cgl-mazeout-test.appspot.com',
		messagingSenderId: '832980945862',
		appId: '1:832980945862:web:c6cf7f4747f3e895e040c7'
	};
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyCR8HsX19uiy2qCa3ybRdXz9gm-OHwpMqU',
		authDomain: 'cgl-mazeout-production.firebaseapp.com',
		databaseURL: 'https://cgl-mazeout-production.firebaseio.com',
		projectId: 'cgl-mazeout-production',
		storageBucket: 'cgl-mazeout-production.appspot.com',
		messagingSenderId: '599444636911',
		appId: '1:599444636911:web:76508d5d96d0495c7b871a',
		measurementId: 'G-ZJBZ0PTEC1'
	};
}

/* Connect to firebase: english version */
if (env === 'english') {
	firebaseConfig = {
		apiKey: 'AIzaSyCBXIyr3GlrDfPlGxo9I2sDePMSiquKhF4',
		authDomain: 'cgl-mazeout-english.firebaseapp.com',
		projectId: 'cgl-mazeout-english',
		storageBucket: 'cgl-mazeout-english.appspot.com',
		messagingSenderId: '605847319852',
		appId: '1:605847319852:web:98ec2739f8d337295f6889',
		measurementId: 'G-YP94RD8JQH'
	};
}


/* Initialize firebase */
firebase.initializeApp(firebaseConfig);

/* Initialize analytics */
if (env === 'production' || env === 'english') {
	firebase.analytics();
}


export default firebase.firestore().enablePersistence()
	.then(() => {
		console.log('Firestore persistence enabled!');
		return firebase.firestore();
	})
	.catch((error) => {
		if (error.code === 'failed-precondition') {
			console.error('Multiple tabs open, persistence can only be enabled in one tab at a time.');
		} else if (error.code === 'unimplemented') {
			console.error('The current browser does not support all of the features required to enable persistence');
		} else {
			console.error('Unknown persistence error: ', error);
		}
		return firebase.firestore();
	});