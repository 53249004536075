import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Survey from 'components/survey/survey';
import './survey-preview.scss';

const SurveyPreview = ({type, surveyData, showSurveyPreview}) => {

	let [stepIndex, setStepIndex] = useState(0);

	return (
		<div className={'SurveyPreview SurveyPreview--' + type}>

			<div className="SurveyPreview-content">
				<div className="SurveyPreview-closeBtn" onClick={() => {showSurveyPreview(null);}} />
				<div className="SurveyPreview-survey">
					<Survey 
						isPreview={true}
						stepIndex={stepIndex}
						surveyData={surveyData}
						goToStep={setStepIndex}
						handleUpdateInput={() => {return null;}}
						toggleOption={() => {return null;}}
						handleSaveSurvey={() => {showSurveyPreview(null);}}
					/>
				</div>
			</div>
		</div>
	);
};


SurveyPreview.propTypes = {
	type: PropTypes.string.isRequired,
	surveyData: PropTypes.object.isRequired,
	showSurveyPreview: PropTypes.func.isRequired
};

export default SurveyPreview;