import React from 'react';
import PropTypes from 'prop-types';
import {errorUiTexts} from 'data/ui-texts';
import apiHelper from 'helpers/api-helper';
import SurveysPrint from './surveys-print';


class SurveysPrintController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isSendingEmail: false,
			emailSent: false,
			feedback: null
		};
	}

	handleSendEmail = (email) => {
		this.setState({isSendingEmail: true, feedback: null, emailSent: false}, () => {
			if (this.isValid(email)) {
				apiHelper('send-email', {
					name: this.props.playerData.id, 
					email, surveys: this.props.playerData.surveys
				}).then((response) => {
					if (response.status === 'success') {
						this.setState({isSendingEmail: false, emailSent: true, feedback: null});
					} else {
						console.error(response.error);
						this.setState({isSendingEmail: false, emailSent: false, feedback: null});
					}
				}).catch((error) => {
					/* Connnection / database error */
					console.error(error);
					this.setState({isSendingEmail: false, emailSent: false, feedback: null});
				});
			} else {
				this.setState({isSendingEmail: false, emailSent: false, feedback: errorUiTexts.invalidEmail});
			}
		});
	}

	isValid = (email) => {
		let re = /\S+@\S+/;
		return re.test(email);
	}


	/**
	 * Render component
	 */
	render = () => {
		return (
			<SurveysPrint 
				isSendingEmail={this.state.isSendingEmail}
				emailSent={this.state.emailSent}
				feedback={this.state.feedback}
				handleSendEmail={this.handleSendEmail}
				closePopup={this.props.closePopup}
			/>
		);
	}
}

SurveysPrintController.propTypes = {
	playerData: PropTypes.object,
	closePopup: PropTypes.func.isRequired
};

export default SurveysPrintController;