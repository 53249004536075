import React from 'react';
import PropTypes from 'prop-types';
import {imagesData} from 'data/images-data';
import './image-loader.scss';

const ImageLoader = ({cardImages}) => {
	return (
		<div className="ImageLoader">
			{imagesData.concat(cardImages).map((imageData, index) => {
				let imgSrc = null;
				if (typeof imageData === 'string') {
					imgSrc = require('../../assets/images/' + imageData);
				} else {
					if (imageData && imageData.hasOwnProperty('url')) imgSrc = imageData.url;
				}
				return (
					<img 
						key={index}
						src={imgSrc}
						alt="preload"
					/>
				);				
			})}
		</div>
	);
};

ImageLoader.propTypes = {
	cardImages: PropTypes.array.isRequired
};

export default ImageLoader;