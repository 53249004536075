import React, {useState} from 'react';
import PropTypes from 'prop-types';
// import statsData from 'data/stats-data';
import {cardTypes, nextCardTypes} from 'data/cards-data';
import secretsData from 'data/secrets-data';
import {missionRequirementTypes} from 'data/missions-data';
import {surveyTypes} from 'data/surveys-data';
import {sortArrayByProperty} from 'helpers/array-helper';
import Select from 'components/select/select';
import CardPreview from './card-preview';
import './card-popup.scss';

const CardPopup = (props) => {
	let {isEditingCard, isSaving, card, decks, cards, missions, images, updateCard, selectCard, saveCard} = props;

	let [showCardPreview, setShowCardPreview] = useState(false);

	let saveCardBtnClass = 'CardPopup-saveCardBtn' + 
	(isEditingCard ? ' CardPopup-saveCardBtn--active' : '') + 
	(isEditingCard && isSaving ? 'CardPopup-saveCardBtn--saving' : '');

	let answers = ['answerA', 'answerB'];
	let availableCardImages = [''].concat(images);
	let availableSecrets = [''].concat(secretsData);
	let availableMissions = [''].concat(missions);
	let availableSurveyTypes = [''].concat(surveyTypes);

	return (
		<div className="CardPopup">
			<div className="CardPopup-content">
				<div className="CardPopup-backBtn" onClick={() => {selectCard(null);}} />
				<div className="CardPopup-cardId"><span>ID: </span>{card.id}</div>

				{/* Save button */}
				<div className={saveCardBtnClass} onClick={() => {saveCard();}}>Gem</div>
				<div className="CardPopup-previewBtn" onClick={() => {setShowCardPreview(true);}}>Preview</div>
				
				{/* Card data */}
				<div className="CardPopup-section CardPopup-section--card">
					<div className="CardPopup-subsection">
						<div className="CardPopup-cardInfo">
							<span>Titel:</span>
							<input 
								type="text"
								name="title"
								value={card.title}
								onChange={(event) => {updateCard(event);}}
							/>
						</div>
						<div className="CardPopup-cardInfo">
							<span>Type:</span>
							<Select 
								type={'isRandom'} 
								options={cardTypes} 
								defaultValue={'random'} 
								selectedId={(card.isRandom ? 'R' : 'P')}
								onSelect={updateCard}
							/>
						</div>
						{card.deckId === 'basic' && <div className="CardPopup-cardInfo">
							<span>Maze:</span>
							<input 
								className="mazeId"
								type="number"
								name="mazeId"
								value={card.mazeId ? card.mazeId : ''}
								onChange={(event) => {updateCard(event);}}
							/>-
							<input 
								className="mazeId"
								type="number"
								name="mazeIdMax"
								value={card.mazeIdMax ? card.mazeIdMax : ''}
								onChange={(event) => {updateCard(event);}}
							/>
						</div>}
						<div className="CardPopup-cardInfo">
							<span>Billede:</span>
							<Select
								type="imageFile"
								options={availableCardImages}
								defaultValue=''
								selectedId={(card.imageFile ? card.imageFile : '')}
								onSelect={updateCard}
							/>
						</div>
					</div>
					<div className="CardPopup-subsection">
						<div className="CardPopup-cardInfo">
							<span>Tekst:</span>
							<textarea 
								name="text"
								value={card.text}
								placeholder=""
								onChange={(event) => {updateCard(event);}}
							/>
						</div>
					</div>
				</div>

				{/* Answer A / B data */}
				{answers.map((answer, index) => {
					if (card.hasOwnProperty(answer)) {
						return (
							<div key={index} className="CardPopup-section CardPopup-section--answer">
								<div className="CardPopup-sectionTitle">Svar {(index === 0 ? 'A' : 'B')}</div>
								<div className="CardPopup-subsection">
									<div className="CardPopup-cardInfo">
										<span>Tekst:</span><br />
										<textarea 
											style={{height: '5em'}}
											name="text"
											value={card[answer].text}
											placeholder=""
											onChange={(event) => {updateCard(event, answer);}}
										/>
									</div>
									{/* <div className="CardPopup-cardInfo">
										<span>Effekt:</span><br />
										{statsData.stats.map((stat) => {
											return (
												<input 
													className={'CardPopup-statInput CardPopup-statInput--' + stat.id}
													key={stat.id}
													type="number"	
													name={stat.id}
													value={(card[answer].stats && 
														card[answer].stats.hasOwnProperty(stat.id)
														? card[answer].stats[stat.id] : 0)}
													onChange={(event) => {updateCard(event, answer, 'stats');}}
												/>
											);
										})}
									</div> */}
								</div>
								<div className="CardPopup-subsection">
									<div className="CardPopup-cardInfo">
										<span>Feedback:</span>
										<textarea 
											name="defaultText"
											value={(card[answer].feedback ? card[answer].feedback.defaultText : '')}
											placeholder=""
											onChange={(event) => {updateCard(event, answer, 'feedback');}}
										/>
									</div>
									<div className="CardPopup-cardInfo">
										{(card[answer].feedback && card[answer].feedback.defaultText) && 
											<React.Fragment> 
												<span>Feedback2:</span>
												<Select 
													type="type"
													extraParams={[answer, 'feedback', 'condition']}
													options={[
														'', 
														{id: 'secret', value: 'secret', title: 'krav: hemmelighed'}
													]} 
													defaultValue={''} 
													selectedId={((card[answer].feedback && 
														card[answer].feedback.condition &&
														card[answer].feedback.condition.type) 
														? card[answer].feedback.condition.type 
														: ''
													)}
													onSelect={updateCard}
												/><br />
											</React.Fragment>
										}
										{(card[answer].feedback && card[answer].feedback.condition && 
											card[answer].feedback.condition.type && 
											card[answer].feedback.condition.type === 'secret') && 
											<React.Fragment>
												<span></span>
												<Select 
													type="secretId"
													extraParams={[answer, 'feedback', 'condition']}
													options={secretsData} 
													defaultValue={''} 
													selectedId={((card[answer].feedback.condition && 
														card[answer].feedback.condition.secretId) 
														? card[answer].feedback.condition.secretId
														: ''
													)}
													onSelect={updateCard}
												/><br />
											</React.Fragment>
										}
										{(card[answer].feedback && card[answer].feedback.condition && 
											card[answer].feedback.condition.type && 
											card[answer].feedback.condition.secretId) &&
											<React.Fragment>
												<span></span>
												<textarea 
													name="text"
													value={(card[answer].feedback && card[answer].feedback.condition 
														&& card[answer].feedback.condition.text 
														? card[answer].feedback.condition.text : '')}
													placeholder="alternativ feedback"
													onChange={(event) => {
														updateCard(event, answer, 'feedback', 'condition');
													}}
												/>
											</React.Fragment>
										}
									</div>
									<div className="CardPopup-cardInfo">
										<span>Hemmelighed:</span>
										<Select 
											type="secretId"
											extraParams={[answer]}
											options={availableSecrets} 
											defaultValue={''} 
											selectedId={card[answer].secretId}
											onSelect={updateCard}
										/><br />
									</div>
									<div className="CardPopup-cardInfo">
										<span>Mission:</span>
										<Select 
											type="missionId"
											extraParams={[answer, 'mission']}
											options={availableMissions} 
											defaultValue={''} 
											selectedId={(card[answer].mission ? card[answer].mission.missionId : '')}
											onSelect={updateCard}
										/><br />
										{(card[answer].mission && card[answer].mission.missionId) &&
											<React.Fragment>
												<span></span>
												<Select 
													type="locksCards"
													extraParams={[answer, 'mission']}
													options={[{id: 'no-lock', title: 'låser ingen kort', value: null}]
														.concat(sortArrayByProperty(cards, 'deckId', 'ASC', 
															'index', 'ASC'))} 
													defaultValue={''} 
													selectedId={((card[answer].mission && 
														card[answer].mission.locksCards && 
														card[answer].mission.locksCards.length > 0 &&
														card[answer].mission.locksCards[0]
													) 
														? card[answer].mission.locksCards[0]
														: 'no-lock'
													)}
													onSelect={updateCard}
												/><br />
											</React.Fragment>
										}
										{(card[answer].mission && card[answer].mission.missionId) &&
											<React.Fragment>
												<span></span>
												<Select 
													type="conditionType"
													extraParams={[answer, 'mission']}
													options={missionRequirementTypes} 
													defaultValue={''} 
													selectedId={((card[answer].mission && 
														card[answer].mission.conditionType) 
														? (card[answer].mission.conditionType === 'none' ? 1 : 2)
														: 1
													)}
													onSelect={updateCard}
												/><br />
											</React.Fragment>
										}
										{(card[answer].mission && card[answer].mission.missionId && 
											card[answer].mission.conditionType === 'secret') &&
											<React.Fragment>
												<span></span>
												<Select 
													type="secretId"
													extraParams={[answer, 'mission']}
													options={secretsData} 
													defaultValue={''} 
													selectedId={((card[answer].mission && 
														card[answer].mission.secretId) 
														? card[answer].mission.secretId 
														: ''
													)}
													onSelect={updateCard}
												/><br />
											</React.Fragment>
										}
									</div>
									<div className="CardPopup-cardInfo">
										<span>Survey:</span>
										<Select 
											type="surveyType"
											extraParams={[answer, 'survey']}
											options={availableSurveyTypes} 
											defaultValue={''} 
											selectedId={(card[answer].survey && card[answer].survey.surveyType 
												? card[answer].survey.surveyType : '')}
											onSelect={updateCard}
										/><br />
									</div>									
									<div className="CardPopup-cardInfo">
										<span>Next card:</span>
										<Select 
											type="type"
											extraParams={[answer, 'nextCard']}
											options={(card.deckId === 'intro' 
												? nextCardTypes.concat([{
													id: 'mazeGame', 
													title: 'start spil', 
													value: 'mazeGame'
												}])
												: nextCardTypes)} 
											defaultValue={'random'} 
											selectedId={(card[answer].nextCard ? card[answer].nextCard.type : '')}
											onSelect={updateCard}
										/><br />
										{(card[answer].nextCard && card[answer].nextCard.type && 
											card[answer].nextCard.type !== 'mazeGame') &&
											<React.Fragment>
												<span></span>
												<Select 
													type="deckId"
													extraParams={[answer, 'nextCard']}
													options={decks} 
													defaultValue={''} 
													selectedId={((card[answer].nextCard && 
														card[answer].nextCard.deckId)  
														? (card[answer].nextCard.deckId)
														: ''
													)}
													onSelect={updateCard}
												/><br />
											</React.Fragment>
										}
										{(card[answer].nextCard && card[answer].nextCard.type && 
											card[answer].nextCard.type === 'specific' &&
											card[answer].nextCard.deckId) &&
											<React.Fragment>
												<span></span>
												<Select 
													type="cardId"
													extraParams={[answer, 'nextCard']}
													options={sortArrayByProperty(cards.filter((cardData) => {
														return cardData.deckId === card[answer].nextCard.deckId;
													}), 'index', 'ASC')} 
													defaultValue={''} 
													selectedId={((card[answer].nextCard && 
														card[answer].nextCard.cardId)  
														? (card[answer].nextCard.cardId)
														: ''
													)}
													onSelect={updateCard}
												/><br />
											</React.Fragment>
										}
									</div>
								</div>
							</div>
						);
					} 
					return null;
				})}

				{/* Answer button data */}
				{card.hasOwnProperty('button') && <div className="CardPopup-section">
					<div className="CardPopup-sectionTitle">Popup-format</div>
					<div className="CardPopup-cardInfo">
						<span>Knap:</span>
						<input 
							name="text"
							value={card.button.text}
							placeholder=""
							onChange={(event) => {updateCard(event, 'button');}}
						/>
					</div>
					<div className="CardPopup-cardInfo">
						<span>Next card:</span>
						<Select 
							type="type"
							extraParams={['button', 'nextCard']}
							options={nextCardTypes.concat([{id: 'mazeGame', title: 'start spil', value: 'mazeGame'}])} 
							defaultValue={'random'} 
							selectedId={(card.button.nextCard && card.button.nextCard.type 
								? card.button.nextCard.type : '')}
							onSelect={updateCard}
						/><br />
						{(card.button && card.button.nextCard && card.button.nextCard.type && 
							card.button.nextCard.type !== 'mazeGame') &&
							<React.Fragment>
								<span></span>
								<Select 
									type="deckId"
									extraParams={['button', 'nextCard']}
									options={decks} 
									defaultValue={''} 
									selectedId={((card.button.nextCard && 
										card.button.nextCard.deckId)  
										? (card.button.nextCard.deckId)
										: ''
									)}
									onSelect={updateCard}
								/><br />
							</React.Fragment>
						}
						{(card.button && card.button.nextCard && card.button.nextCard.type && 
								card.button.nextCard.type === 'specific' &&
								card.button.nextCard.deckId) &&
								<React.Fragment>
									<span></span>
									<Select 
										type="cardId"
										extraParams={['button', 'nextCard']}
										options={sortArrayByProperty(cards.filter((cardData) => {
											return cardData.deckId === card.button.nextCard.deckId;
										}), 'index', 'ASC')} 
										defaultValue={''} 
										selectedId={((card.button.nextCard && 
														card.button.nextCard.cardId)  
											? (card.button.nextCard.cardId)
											: ''
										)}
										onSelect={updateCard}
									/><br />
								</React.Fragment>
						}
					</div>		
				</div>}
			</div>

			{showCardPreview && <CardPreview 
				type="box"
				cardData={card} 
				showCardPreview={setShowCardPreview}/>}
			
		</div>
	);
};



CardPopup.propTypes = {
	isEditingCard: PropTypes.bool.isRequired,
	isSaving: PropTypes.bool.isRequired,
	decks: PropTypes.array.isRequired,
	cards: PropTypes.array.isRequired,
	missions: PropTypes.array.isRequired,
	images: PropTypes.array.isRequired,
	card: PropTypes.object.isRequired,
	updateCard: PropTypes.func.isRequired,
	selectCard: PropTypes.func.isRequired,
	saveCard: PropTypes.func.isRequired
};

export default CardPopup;