import React from 'react';
import PropTypes from 'prop-types';
import {loginUiTexts} from './ui-texts';
import './game-stats-login.scss';

const GameStatsLogin = ({
	handleInput,
	handleLogin,
	email,
	password,
	feedback,
	isLoading
}) => {
	let buttonClass = 'Button Button--login' + (isLoading ? ' Button--loading' : '');
	let emailValue = email ? email : '';
	let passwordValue = password ? password : '';

	let feedbackText = (feedback 
		? (loginUiTexts.hasOwnProperty(feedback) ? loginUiTexts[feedback] : loginUiTexts.unknownError)
		: null
	);

	return (
		<div className="GameStatsLogin">
			<div className="GameStatsLogin-title">Admin</div>
			<form className="GameStatsLogin-form" onSubmit={(event) =>{handleLogin(event);}}>
				<input 
					className='Input' 
					name="email" 
					placeholder={loginUiTexts.email}
					autoComplete="section-userlogin email"
					value={emailValue} 
					onChange={(event)=>{handleInput(event);}}
				/><br />
				<input 
					className='Input' 
					name="password"
					type="password"
					placeholder={loginUiTexts.password}
					autoComplete="section-userlogin password"
					value={passwordValue} 
					onChange={(event)=>{handleInput(event);}}
				/><br />
				<button className={buttonClass} onClick={(event)=>{handleLogin(event);}}>
					{loginUiTexts.login}
				</button>
				<p className={'GameStatsLogin-errorMessage'}>{feedbackText}</p>
			</form>
		</div>
	);
};

GameStatsLogin.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	email: PropTypes.string,
	password: PropTypes.string,
	feedback: PropTypes.string,
	handleLogin: PropTypes.func.isRequired,
	handleInput: PropTypes.func.isRequired	
};

export default GameStatsLogin;