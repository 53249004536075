import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import Card from 'components/card/card';
import CardFeedback from 'components/card/card-feedback';
import './card-preview.scss';

const CardPreview = ({type, cardData, showCardPreview}) => {

	let [cardDirection, setCardDirection] = useState('');
	let [optionData, setOptionData] = useState(null);

	return (
		<div className={'CardPreview CardPreview--' + type}>

			<div className="CardPreview-content">
				<div className="CardPreview-closeBtn" onClick={() => {showCardPreview(null);}} />
				<div className="CardPreview-text">{cardData && renderMarkdown(cardData.text)}</div>
				<div className="CardPreview-cardContainer">
					{optionData === null && <Card 
						cardData={cardData}
						// color="pink"
						selectedOption={null}
						cardDirection={cardDirection}
						setCardDirection={setCardDirection}
						handleSelectOption={setOptionData}
					/>}
					{(optionData && optionData.feedback) && 
						<CardFeedback 
							playerData={{secretsDiscovered: []}}
							// color="pink"
							feedbackType="feedback"
							selectedOption={optionData}
							missionData={null}
							goToNextGameStep={() => {console.log('next');}}
						/>
					}
				</div>
			</div>
		</div>
	);
};


CardPreview.propTypes = {
	type: PropTypes.string.isRequired,
	cardData: PropTypes.object.isRequired,
	showCardPreview: PropTypes.func.isRequired
};

export default CardPreview;