import React from 'react';
import PropTypes from 'prop-types';
import {errorUiTexts} from 'data/ui-texts';
import './connection-status.scss';

const ConnectionStatus = ({isOnline}) => {
	return (
		<div className={'ConnectionStatus' + (!isOnline ? ' ConnectionStatus--offline' : '')}>
			<div className="ConnectionStatus-text">{errorUiTexts.offline}</div>
		</div>
	);	
};

ConnectionStatus.propTypes = {
	isOnline: PropTypes.bool.isRequired
};

export default ConnectionStatus;