import secretsData from 'data/secrets-data';


function getDefaultAnswerObj(deckId) {
	return {
		text: '',
		stats: {},
		feedback: null,
		mission: null,
		secretId: null,
		survey: null,
		nextCard: {type: 'random', deckId: deckId}
	};
}

/**
 * Get card updates
 * @param {object} selectedCard 
 */
function getCardUpdates(selectedCard, missionsData) {
	/* Basic properties */
	let title = (selectedCard.title && selectedCard.title.length > 0 ? selectedCard.title : 'titel');
	let imageFile = null;
	if (selectedCard.imageFile) imageFile = selectedCard.imageFile;
	let isRandom = false;
	if (selectedCard.hasOwnProperty('isRandom')) isRandom = selectedCard.isRandom;
	let mazeId = (selectedCard.mazeId ? parseInt(selectedCard.mazeId) : null);
	let mazeIdMax = (selectedCard.mazeIdMax ? parseInt(selectedCard.mazeIdMax) : null);
	let text = (selectedCard.text && selectedCard.text.length > 0 ? selectedCard.text : '');
	let cardUpdates = {title, imageFile, isRandom, mazeId, mazeIdMax, text};

	/* Answer options A/B */
	if (!selectedCard.hasOwnProperty('button')) {
		let answerOptions = ['answerA', 'answerB'];
		answerOptions.forEach((answer) => {
		/* Default answer object */
			let answerObj = getDefaultAnswerObj(selectedCard.deckId);

			/* Custom properties */
			if (selectedCard[answer]) {
			/* Text */ 
				if (selectedCard[answer].text && selectedCard[answer].text.length > 0) {
					answerObj.text = selectedCard[answer].text;
				}

				/* Stats */
				if (selectedCard[answer].stats) answerObj.stats = selectedCard[answer].stats;

				/* Feedback */
				if (selectedCard[answer].feedback && selectedCard[answer].feedback.defaultText) {
					answerObj.feedback = {defaultText: selectedCard[answer].feedback.defaultText};
					/* Conditional feedback */
					if (selectedCard[answer].feedback.condition && 
						selectedCard[answer].feedback.condition.text && 
						selectedCard[answer].feedback.condition.text.length > 0 &&
						selectedCard[answer].feedback.condition.type 
					) {
						answerObj.feedback.condition = selectedCard[answer].feedback.condition;
					}
				}
				

				/* Survey */
				if (
					selectedCard[answer].survey && selectedCard[answer].survey.surveyType
				) {
					answerObj.survey = {
						isRandomSurvey: true,
						surveyType: selectedCard[answer].survey.surveyType
					};
				}

				/* Secret */
				if (
					selectedCard[answer].secretId && 
					secretsData.some((secret) => {return secret.id === selectedCard[answer].secretId;})
				) {
					answerObj.secretId = selectedCard[answer].secretId;
				}

				/* Mission */
				if (
					selectedCard[answer].mission && selectedCard[answer].mission.missionId && 
				missionsData.some((mission) => {return mission.id === selectedCard[answer].mission.missionId;})
				) {
					answerObj.mission = {missionId: selectedCard[answer].mission.missionId};

					if (
						selectedCard[answer].mission.locksCards &&
						selectedCard[answer].mission.locksCards.length > 0 &&
						selectedCard[answer].mission.locksCards[0]
					) {
						answerObj.mission.locksCards = selectedCard[answer].mission.locksCards;
					}
				
					if (
						selectedCard[answer].mission.conditionType === 'secret' && 
						selectedCard[answer].mission.secretId && 
						secretsData.some((secret) => {return secret.id === selectedCard[answer].mission.secretId;})
					) {
						answerObj.mission.conditionType = 'secret';
						answerObj.mission.secretId = selectedCard[answer].mission.secretId;
					} else {
						answerObj.mission.conditionType = 'none';
					}
				}

				/* Next card */
				if (
					selectedCard[answer].nextCard && 
				(
					(
						(
							selectedCard[answer].nextCard.type === 'random' ||  
							selectedCard[answer].nextCard.type === 'specific'
						) && selectedCard[answer].nextCard.deckId
					) || (
						selectedCard[answer].nextCard.type === 'mazeGame' && selectedCard.deckId === 'intro'
					)
				) // && 
				// decksData.some((deck) => {return deck.id === selectedCard[answer].nextCard.deckId;})
				) {
					answerObj.nextCard.type = selectedCard[answer].nextCard.type;
					if (selectedCard[answer].nextCard.type !== 'mazeGame') {
						answerObj.nextCard.deckId = selectedCard[answer].nextCard.deckId;
					} else {
						answerObj.nextCard.deckId = null;
					}
					if (
						selectedCard[answer].nextCard.type === 'specific' && 
					selectedCard[answer].nextCard.cardId // &&
					// decksData.filter((deck) => {
					// 	return deck.id === selectedCard[answer].nextCard.deckId;
					// })[0].cards.some((card) => {return card.id === selectedCard[answer].nextCard.cardId;})
					) {
						answerObj.nextCard.cardId = selectedCard[answer].nextCard.cardId;
					}
				}
			}

			if (answer === 'answerA') cardUpdates.answerA = answerObj;
			if (answer === 'answerB') cardUpdates.answerB = answerObj;
		});
	}

	/* Answer by clicking button */
	if (selectedCard.hasOwnProperty('button')) {
		let buttonObj = {text: 'Ok', nextCard: {type: 'random', deckId: selectedCard.deckId}};

		/* Button text */
		if (selectedCard.button && selectedCard.button.text && selectedCard.button.text.length > 0) {
			buttonObj.text = selectedCard.button.text;
		}

		/* Next card */
		if (
			selectedCard.button.nextCard && (
				(
					(
						selectedCard.button.nextCard.type === 'random' ||  
						selectedCard.button.nextCard.type === 'specific'
					) && 
					selectedCard.button.nextCard.deckId // && 
					// decksData.some((deck) => {return deck.id === selectedCard.button.nextCard.deckId;})
				) || (
					selectedCard.deckId === 'intro' && 
					selectedCard.button.nextCard.type === 'mazeGame'
				)
			)
		) {
			buttonObj.nextCard.type = selectedCard.button.nextCard.type;
			if (selectedCard.button.nextCard.type === 'mazeGame') {
				buttonObj.nextCard.deckId = null;
			} else {
				buttonObj.nextCard.deckId = selectedCard.button.nextCard.deckId;
			}
			if (
				selectedCard.button.nextCard.type === 'specific' && 
				selectedCard.button.nextCard.cardId // &&
				// decksData.filter((deck) => {
				// 	return deck.id === selectedCard.button.nextCard.deckId;
				// })[0].cards.some((card) => {return card.id === selectedCard.button.nextCard.cardId;})
			) {
				buttonObj.nextCard.cardId = selectedCard.button.nextCard.cardId;
			}
		}

		cardUpdates.button = buttonObj;
	}

	return cardUpdates;
}


function getCardTemplate(deckId) {
	let answerObj = getDefaultAnswerObj(deckId);
	let cardObj = {
		deckId: deckId,
		// color: 'pink',
		title: 'titel',
		imageFile: null,
		isRandom: false,
		text: 'text',
		answerA: JSON.parse(JSON.stringify(answerObj)),
		answerB: JSON.parse(JSON.stringify(answerObj))
	};
	return cardObj;
}

function getSurveyTemplate(surveyId) {
	let surveyObj = {
		id: surveyId,
		title: 'Titel',
		surveyType: 'gameover',
		mazeId: 1,
		steps: [
			getStepTemplate(0)
		]
	};
	return surveyObj;
}

function getStepTemplate(index) {
	let stepObj = {
		index: index,
		type: 'intro',
		title: (index === 0 ? 'Refleksionsøvelse' : ''),
		image: (index === 0 ? 'therapist.svg' : ''),
		text: 'Tekst',
		buttonText: 'ok'
	};
	return stepObj;
}

function getSurveyUpdates(selectedSurvey) {
	/* Basic properties */
	let surveyType = selectedSurvey.surveyType;
	let title = (selectedSurvey.title && selectedSurvey.title.length > 0 ? selectedSurvey.title : 'titel');
	let mazeId = (selectedSurvey.mazeId && selectedSurvey.mazeId > 0 ? selectedSurvey.mazeId : 1);
	let surveyUpdates = {title, surveyType, mazeId, steps: []};

	/* Steps */
	selectedSurvey.steps.forEach((step, stepIndex) => {
		/* Default properties */
		let stepUpdates = {index: stepIndex, type: step.type};
		stepUpdates.text = (step.text ? step.text : '');
		stepUpdates.buttonText = (step.buttonText && step.buttonText.length > 0 
			? step.buttonText 
			: (stepIndex + 1 === selectedSurvey.steps.length ? 'gem' : 'ok') 
		);

		/* Title */
		if (step.type === 'intro') {
			stepUpdates.title = (step.title && step.title.length > 0 ? step.title : '');
		}

		/* Image */
		if (step.type === 'intro') {
			stepUpdates.image = (step.image && step.image.length > 0 ? step.image : '');
		}

		/* Input */
		if (step.type === 'textarea' || step.type === 'multiple-choice') {
			stepUpdates.input = '';
		}
		if (step.type === 'slider') {
			stepUpdates.input = 0;
		}

		/* Input placeholder */
		if (step.type === 'multiple-choice') {
			stepUpdates.inputPlaceholder = (step.inputPlaceholder && step.inputPlaceholder.length > 0
				? step.inputPlaceholder
				: 'Andet?'
			);
		}

		/* Options & layoutType */
		if (step.type === 'multiple-choice') {
			let options = [];
			if (step.options && step.options.length > 0) {
				let optionId = 1;
				step.options.forEach((option) => {
					if (option && option.title && option.title.length > 0) {
						options.push({id: optionId, title: option.title, selected: false});
						optionId += 1;
					}
				});
			}
			stepUpdates.options = options;
			stepUpdates.layoutType = (options.length > 7 ? 2 : 1);
		}

		surveyUpdates.steps.push(stepUpdates);
	});

	return surveyUpdates;
}


export {
	getCardUpdates,
	getCardTemplate,
	getSurveyTemplate,
	getStepTemplate,
	getSurveyUpdates
};