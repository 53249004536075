import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {renderMarkdown} from 'helpers/text-helper';
import Card from 'components/card/card';
import CardFeedback from 'components/card/card-feedback';
import './maze-game.scss';

const MazeGame = (props) => {
	let {
		showEndOfDreamCard,
		playerData, 
		cardData, 
		selectedOption, 
		showFeedback,
		missionData,
		showMissionCompletedFeedback,
		handleSelectOption, 
		goToNextGameStep,
		handleToggleMainMenu
	} = props;

	let [cardDirection, setCardDirection] = useState('');

	let cardContainerClass = 'MazeGame-cardContainer' +
		(cardData ? ' MazeGame-cardContainer--id-' + cardData.id : '');

	let CardComponent = null;
	let feedbackType = 'feedback';
	if (cardData) {
		CardComponent = Card;
		if (
			showMissionCompletedFeedback ||
			showEndOfDreamCard ||
			(selectedOption && selectedOption.hasOwnProperty('feedback') && selectedOption.feedback !== null)
		) {
			CardComponent = CardFeedback;
			if (missionData && showMissionCompletedFeedback) feedbackType = 'mission';
			if (showEndOfDreamCard) feedbackType = 'gameover';
		}
	}

	let showText = (cardData && !showFeedback && !showMissionCompletedFeedback && !showEndOfDreamCard);

	return (
		<div className={'MazeGame' + (cardData ? ' ' + cardData.deckId : '')}>
			<div className="MazeGame-text">{showText && renderMarkdown(cardData.text)}</div>
			<div className={cardContainerClass}>
				{CardComponent &&
					<CardComponent 
						playerData={playerData} 
						cardData={cardData} 
						feedbackType={feedbackType}
						selectedOption={selectedOption}
						missionData={missionData}
						cardDirection={cardDirection}
						setCardDirection={setCardDirection} 
						handleSelectOption={handleSelectOption}
						goToNextGameStep={goToNextGameStep}
					/>}
			</div>
			<div className="MazeGame-overviewBtn" onClick={() => {handleToggleMainMenu();}} />
		</div>	
	);
};

MazeGame.defaultProps = {
	cardData: null,
	deckData: null,
	selectedOption: null,
	missionData: null
};

MazeGame.propTypes = {
	showEndOfDreamCard: PropTypes.bool.isRequired,
	playerData: PropTypes.object.isRequired,
	cardData: PropTypes.object,
	selectedOption: PropTypes.object,
	showFeedback: PropTypes.bool.isRequired,
	missionData: PropTypes.object,
	showMissionCompletedFeedback: PropTypes.bool.isRequired,
	handleSelectOption: PropTypes.func.isRequired,
	goToNextGameStep: PropTypes.func.isRequired,
	handleToggleMainMenu: PropTypes.func.isRequired
};

export default MazeGame;