import React from 'react';
import PropTypes from 'prop-types';
import {getCurrentDate} from 'helpers/date-helper';
import Survey from './survey';

class SurveyController extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			stepIndex: 0,
			surveyData: null
		};
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		/* Get survey data */
		let surveyData = JSON.parse(JSON.stringify(this.props.surveysData)).find((survey) => {
			return survey.id === this.props.surveyId;
		});

		/* Update state */
		this.setState({isLoading: false, surveyData});
	}

	/**
	 * Go to survey step
	 * @param {number} stepIndex 
	 */
	goToStep = (stepIndex) => {
		this.setState({stepIndex});
	}

	/**
	 * Update input 
	 */
	handleUpdateInput = (event) => {
		let name = event.target.name;
		let value = event.target.value;

		let surveyData = JSON.parse(JSON.stringify(this.state.surveyData));
		if (surveyData.steps[this.state.stepIndex].hasOwnProperty(name)) {
			surveyData.steps[this.state.stepIndex][name] = value;
			this.setState({surveyData});
		}
	}

	/**
	 * Select / deselect option
	 * @param {number} optionId 
	 */
	toggleOption = (optionId) => {
		let surveyData = JSON.parse(JSON.stringify(this.state.surveyData));
		surveyData.steps[this.state.stepIndex].options.forEach((option) => {
			if (option.id === optionId) option.selected = !option.selected;
		});
		this.setState({surveyData});
	}

	/**
	 * Save survey and continue game
	 * @param {bool} skipSave 
	 */
	handleSaveSurvey = () => {
		let surveyData = null;
		if (this.state.surveyData) {
			let surveyDate = getCurrentDate();
			surveyData = {
				id: this.props.surveyId, 
				type: this.state.surveyData.surveyType,
				surveyDate, 
				inputs: Array.apply(null, Array(this.state.surveyData.steps.length)).map(() => {return null;})
			};

			this.state.surveyData.steps.forEach((step, index) => {
				if (step.type === 'textarea' || step.type === 'slider') surveyData.inputs[index] = step.input;
				if (step.type === 'multiple-choice' && step.options && step.options.length > 0) {
					let input = '';
					step.options.forEach((option) => {
						if (option.selected === true) input += ' ' + option.title + ',';
					});
					if (step.input && step.input.length > 0) input += ' ' + step.input;
					surveyData.inputs[index] = input;
				}
			});
		}
		this.props.handleCompleteSurvey(surveyData);
	}

	/**
	 * Render component
	 */
	render() {
		return (
			<Survey 
				stepIndex={this.state.stepIndex}
				surveyData={this.state.surveyData} 
				goToStep={this.goToStep}
				handleUpdateInput={this.handleUpdateInput}
				toggleOption={this.toggleOption}
				handleSaveSurvey={this.handleSaveSurvey}
			/>
		);
	}
}

SurveyController.propTypes = {
	surveyId: PropTypes.string.isRequired,
	surveysData: PropTypes.array.isRequired,
	handleCompleteSurvey: PropTypes.func.isRequired
};

export default SurveyController;