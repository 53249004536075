import React from 'react';
import PropTypes from 'prop-types';
import CookieConsentController from 'components/cookie-consent/cookie-consent-controller';
import './splash.scss';

const Splash = ({isLoading, isLoggedIn, splashPageClass, handleGoToPage}) => {
	return (
		<div className={'Splash' + (splashPageClass.length > 0 ? ' Splash--' + splashPageClass : '')}>
			<div 
				className={'Splash-play' + (isLoading ? ' loading' : '')} 
				onClick={() => {(isLoggedIn ? handleGoToPage('game') : handleGoToPage('login'));}}
			/>
			<CookieConsentController />
		</div>				
	);
};

Splash.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	isLoggedIn: PropTypes.bool.isRequired,
	splashPageClass: PropTypes.string.isRequired,
	handleGoToPage: PropTypes.func.isRequired
};

export default Splash;