import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {surveyTypes, stepTypes} from 'data/surveys-data';
import Select from 'components/select/select';
import SurveyPreview from './survey-preview';
import './survey-popup.scss';

const SurveyPopup = (props) => {
	let {
		isCreating, 
		isEditing, 
		isSaving, 
		survey, 
		images, 
		updateSurvey, 
		selectSurvey, 
		createNewStep, 
		saveSurvey
	} = props;

	let [showSurveyPreview, setShowSurveyPreview] = useState(false);

	let saveSurveyBtnClass = 'SurveyPopup-saveSurveyBtn' + 
	(isEditing ? ' SurveyPopup-saveSurveyBtn--active' : '') + 
	(isEditing && isSaving ? ' SurveyPopup-saveSurveyBtn--saving' : '');

	let createStepBtnClass = 'SurveysPopup-newStepBtn' +
		(!isEditing && !isSaving ? ' SurveysPopup-newStepBtn--active' : '') +
		(isCreating ? ' SurveysPopup-newStepBtn--saving' : '');
		
	let availableSurveyImages = [''].concat(images);
	let availableSurveyTypes = surveyTypes;

	return (
		<div className="SurveyPopup">
			<div className="SurveyPopup-content">
				<div className="SurveyPopup-backBtn" onClick={() => {selectSurvey(null);}} />
				<div className="SurveyPopup-surveyId"><span>ID: </span>{survey.id}</div>

				{/* Save button */}
				<div className={saveSurveyBtnClass} onClick={() => {saveSurvey();}}>Gem</div>
				<div className="SurveyPopup-previewBtn" onClick={() => {setShowSurveyPreview(true);}}>Preview</div>
				
				{/* Survey data */}
				<div className="SurveyPopup-section SurveyPopup-section--survey">
					<div className="SurveyPopup-subsection">
						<div className="SurveyPopup-surveyInfo">
							<span>Titel:</span>
							<input 
								type="text"
								name="title"
								value={survey.title}
								onChange={(event) => {updateSurvey(event);}}
							/>
						</div>
						<div className="SurveyPopup-surveyInfo">
							<span>Type:</span>
							<Select 
								type={'surveyType'} 
								options={availableSurveyTypes} 
								defaultValue={'gameover'} 
								selectedId={survey.surveyType}
								onSelect={updateSurvey}
							/>
						</div>
						<div className="SurveyPopup-surveyInfo">
							<span>Maze:</span>
							<input 
								type="number"
								name="mazeId"
								value={survey.mazeId ? survey.mazeId : ''}
								onChange={(event) => {updateSurvey(event);}}
							/>
						</div>
					</div>
				</div>
				<hr />

				{/* Survey steps */}
				<div className="SurveyPopup-section">
					{survey.steps.map((step, stepIndex) => {
						return (
							<div key={stepIndex} className="SurveyPopup-subSection">
								<div className="SurveyPopup-sectionTitle">Del {stepIndex + 1}</div>
								<div className="SurveyPopup-surveyInfo">
									<span>Type:</span>
									<Select 
										type={'type'} 
										extraParams={[stepIndex]}
										options={stepTypes} 
										defaultValue={'intro'} 
										selectedId={step.type}
										onSelect={updateSurvey}
									/>
								</div>
								{step.type === 'intro' && <div className="SurveyPopup-surveyInfo">
									<span>Titel:</span>
									<input 
										type="text"
										name="title"
										value={step.title ? step.title : ''}
										onChange={(event) => {updateSurvey(event, stepIndex);}}
									/>
								</div>}
								{step.type === 'intro' && <div className="SurveyPopup-surveyInfo">
									<span>Billede:</span>
									<Select
										type="image"
										extraParams={[stepIndex]}
										options={availableSurveyImages}
										defaultValue=''
										selectedId={(step.image ? step.image : '')}
										onSelect={updateSurvey}
									/>
								</div>}
								<div className="SurveyPopup-surveyInfo">
									<span>Tekst:</span>
									<textarea 
										name="text"
										value={(step.text ? step.text : '')}
										placeholder=""
										onChange={(event) => {updateSurvey(event, stepIndex);}}
									/>
								</div>
								{step.type === 'multiple-choice' && <div className="SurveyPopup-surveyInfo">
									<span>Valgmuligheder:</span><br />
									{[...Array(9)].map((_, optionIndex) => {
										return (
											<input 
												key={optionIndex}
												type="text"
												name="options"
												value={(
													step.options && step.options.length > optionIndex &&
													typeof step.options[optionIndex] === 'object'
														? step.options[optionIndex].title : '')}
												placeholder={optionIndex + 1}
												onChange={(event) => {updateSurvey(event, stepIndex, optionIndex);}}
											/>
										);
									})}
								</div>}
								<div className="SurveyPopup-surveyInfo">
									<span>Knap:</span>
									<input 
										type="text"
										name="buttonText"
										value={step.buttonText ? step.buttonText : ''}
										onChange={(event) => {updateSurvey(event, stepIndex);}}
									/>
								</div>
							</div>
						);
					})}
					<br />
					<div 
						className={createStepBtnClass} 
						onClick={() => {createNewStep();}}
					>Opret ny del</div>
				</div>
			</div>

			{showSurveyPreview && <SurveyPreview 
				type="box"
				surveyData={survey} 
				showSurveyPreview={setShowSurveyPreview}/>}
			
		</div>
	);
};



SurveyPopup.propTypes = {
	isCreating: PropTypes.bool.isRequired,
	isEditing: PropTypes.bool.isRequired,
	isSaving: PropTypes.bool.isRequired,
	images: PropTypes.array.isRequired,
	survey: PropTypes.object.isRequired,
	updateSurvey: PropTypes.func.isRequired,
	selectSurvey: PropTypes.func.isRequired,
	createNewStep: PropTypes.func.isRequired,
	saveSurvey: PropTypes.func.isRequired
};

export default SurveyPopup;